import { MathUtils } from "three";
import {
  TimelineEvents,
  ScreenEvents,
  AppEvents,
  IStepEvent,
} from "./util/Events";
import uiSfx from "./ui/UISfx";
import { app } from "../client";

export type Mode = "current" | "legacy";

import {
  SHOW_TOTAL_DURATION,
  AVERAGE_VIDEO_DURATION,
  VIDEO_NEARLY_ENDED_THRESHOLD,
} from "../CONSTANTS";
import { Step } from "../client";

export interface ICountdown {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  isComplete: boolean;
}

// util function
const getShowtimes = () => {
  const search = window.location.search;

  if (search.indexOf("legacy") > -1) {
    return [new Date(Date.now() - 1000 * 60 * 60 * 24)];
  }

  if (search.indexOf("current") > -1) {
    return [new Date(Date.now() - SHOW_TOTAL_DURATION / 2)];
  }

  if (search.indexOf("later") > -1) {
    return [new Date(Date.now() + 1000 * 60 * 15.3)]; // 15mins in future
  }

  if (search.indexOf("soon") > -1) {
    return [new Date(Date.now() + 1000 * 60)]; // show starts soon
  }

  return [
    new Date("January 12, 2021 10:05:00 UTC"),
    new Date("January 12, 2021 15:05:00 UTC"),
    new Date("January 12, 2021 20:05:00 UTC"),
  ];
};

export default class TimelineController {
  private timelineStarted: boolean | number = false;
  private showtimes = getShowtimes();
  private countdownKeyTimes = {
    ["countdown-15min"]: 1000 * 60 * 15,
    ["countdown-10min"]: 1000 * 60 * 10,
    ["countdown-5min"]: 1000 * 60 * 5,
    ["countdown-4min"]: 1000 * 60 * 4,
    ["countdown-3min"]: 1000 * 60 * 3,
    ["countdown-2min"]: 1000 * 60 * 2,
    ["countdown-1min"]: 1000 * 60 * 1,
    ["countdown-10sec"]: 1000 * 11,
  };
  private countdownComplete = false;
  private introComplete = false;
  public nextShowtime?: Date;
  private timeOffsetMs?: number;
  private countdownMs?: number;
  private timelineStep = -1;
  private isInit = false;
  private timeline = [
    23,
    22,
    21,
    20,
    19,
    18,
    17,
    16,
    15,
    14,
    13,
    12,
    11,
    10,
    9,
    8,
    7,
    6,
    5,
    4,
    3,
    2,
    1,
    0,
  ];

  static async getServerTimeOffset(): Promise<number> {
    return new Promise(resolve => {
      const timeout = setTimeout(resolve, 3333);
      const xmlhttp = new XMLHttpRequest();
      const beforeFetch = new Date();
      xmlhttp.open("HEAD", "https://www.googleapis.com", true);
      xmlhttp.onreadystatechange = () => {
        let timeOffsetMs = 0;
        if (xmlhttp.readyState == 4) {
          const now = new Date();
          const fetchDuration = now.getTime() - beforeFetch.getTime();
          const serverDateTime = xmlhttp.getResponseHeader("Date");
          if (serverDateTime) {
            const serverTimeMillisGMT = Date.parse(
              new Date(Date.parse(serverDateTime)).toUTCString(),
            );
            const localMillisUTC =
              Date.parse(now.toUTCString()) - fetchDuration * 0.5; // take off half of the fetch duration, to roughly account for the duration of network
            timeOffsetMs = localMillisUTC - serverTimeMillisGMT;
          }

          clearTimeout(timeout);
          resolve(timeOffsetMs);
        }
      };
      xmlhttp.onerror = () => {
        clearTimeout(timeout);
        resolve(0);
      };
      xmlhttp.send(null);
    });
  }

  constructor() {
    this.onVideoscreenEnding = this.onVideoscreenEnding.bind(this);
    this.doCountdown = this.doCountdown.bind(this);
    this.onIntroComplete = this.onIntroComplete.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.startTimeline = this.startTimeline.bind(this);
    this.onTimelineEnded = this.onTimelineEnded.bind(this);
  }

  public get countdown(): ICountdown | false {
    if (this.countdownMs === undefined) return false;
    const seconds = this.countdownMs / 1000;

    const days = Math.floor(seconds / 24 / 60 / 60);
    const hoursLeft = Math.floor(seconds - days * 86400);
    const hours = Math.floor(hoursLeft / 3600);
    const minutesLeft = Math.floor(hoursLeft - hours * 3600);
    const minutes = Math.floor(minutesLeft / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    return {
      days,
      hours,
      minutes,
      seconds: remainingSeconds,
      isComplete: this.countdownMs === 0,
    };
  }

  public get mode(): string {
    if (!this.nextShowtime) return "legacy";
    return "current";
  }

  public async init(): Promise<void> {
    this.timeOffsetMs = await TimelineController.getServerTimeOffset();
    this.calculateNextShowtime();

    if (this.mode === "current") {
      this.doCountdown();
    }

    if (window.location.search.indexOf("keyboard") > -1) {
      window.addEventListener("keydown", this.onKeyDown.bind(this), false);
    }
    window.addEventListener(ScreenEvents.Ending, this.onVideoscreenEnding);
    window.addEventListener(AppEvents.Step, ({ detail }: IStepEvent) => {
      if (detail === Step.PreShow) {
        this.onIntroComplete();
      }
    });
    this.isInit = true;
  }

  private calculateNextShowtime() {
    this.nextShowtime = null;
    for (let i = 0; i < this.showtimes.length; i++) {
      if (
        this.showtimes[i].getTime() + SHOW_TOTAL_DURATION >
        Date.now() + this.timeOffsetMs
      ) {
        this.nextShowtime = this.showtimes[i];
        break;
      }
    }
  }

  private doCountdown() {
    const now = Date.now();
    this.countdownMs = Math.max(
      this.nextShowtime.getTime() - (now + this.timeOffsetMs),
      0,
    );

    const countdownEvent = new CustomEvent(TimelineEvents.CountdownUpdated, {
      detail: this.countdown,
    });
    window.dispatchEvent(countdownEvent);

    for (const key in this.countdownKeyTimes) {
      if (typeof this.countdownKeyTimes[key] === "number") {
        if (Math.abs(this.countdownMs - this.countdownKeyTimes[key]) < 333) {
          this.countdownKeyTimes[key] = false;
          uiSfx.play(key);
        }
      }
    }

    if (this.countdownMs === 0) {
      return this.onCountdownComplete();
    }

    setTimeout(() => {
      requestAnimationFrame(this.doCountdown);
    }, 99);
  }

  private onCountdownComplete() {
    const countdownCompleteEvent = new CustomEvent(
      TimelineEvents.CountdownComplete,
    );
    window.dispatchEvent(countdownCompleteEvent);
    this.countdownComplete = true;
    if (this.introComplete) {
      this.startTimeline();
    }
  }

  private onIntroComplete() {
    this.introComplete = true;

    if (this.countdownComplete) {
      // Start part way through if current show.
      const timelineOffset = Date.now() - this.nextShowtime.getTime();
      const urlParams = new URLSearchParams(window.location.search);
      const startParam = urlParams.get("start");

      const startIndex = startParam
        ? parseInt(startParam)
        : MathUtils.clamp(
            Math.floor(timelineOffset / AVERAGE_VIDEO_DURATION),
            0,
            this.timeline.length - 1,
          );
      requestAnimationFrame(() => {
        this.startTimeline(startIndex);
      });
    }
  }

  private startTimeline(index = 0) {
    if (app.step !== Step.PreShow) return;
    this.timelineStarted = Date.now() - index * AVERAGE_VIDEO_DURATION;
    this.timelineStep = index - 1;
    this.nextStep(index !== 0);

    const timelineStartedEvent = new CustomEvent(
      TimelineEvents.TimelineStarted,
    );
    window.dispatchEvent(timelineStartedEvent);
  }

  private onTimelineEnded() {
    this.calculateNextShowtime();
    const timelineEndedEvent = new CustomEvent(TimelineEvents.TimelineEnded);
    window.dispatchEvent(timelineEndedEvent);
  }

  private nextStep(startsMidShow = false) {
    this.timelineStep++;
    if (this.timelineStep < this.timeline.length) {
      const screenIndex = this.timeline[this.timelineStep];
      this.switchScreen(screenIndex, startsMidShow);
    } else {
      this.onTimelineEnded();
    }
  }

  private onVideoscreenEnding() {
    if (this.timelineStarted) {
      this.nextStep();
    }
  }

  private switchScreen(index: number, startsMidShow = false): void {
    const queueEvent = new CustomEvent(ScreenEvents.QueueScreen, {
      detail: { index, switchImmidiate: startsMidShow },
    });
    window.dispatchEvent(queueEvent);
  }

  private onKeyDown(event: KeyboardEvent): void {
    const keys = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "q",
      "w",
      "e",
      "r",
      "t",
      "y",
      "u",
      "i",
      "o",
      "p",
      "a",
      "s",
      "d",
      "f",
      "g",
    ];
    keys.reverse();
    const keyIndex = keys.indexOf(event.key.toLowerCase());
    if (keyIndex !== -1) {
      this.switchScreen(keyIndex);
    }
    if (event.key.toLowerCase() === "h") {
      this.onTimelineEnded();
    }
  }
}
