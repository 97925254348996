import HUDView from "./views/HUDView";
import IntroView from "./views/IntroView";
import CreditsView from "./views/CreditsView";
import "./UISfx";

import { UIEvents, ScreenEvents, AppEvents, IStepEvent } from "../util/Events";
import { Step } from "../../client";

export default class UIController {
  private hudView?: HUDView;
  private introView?: IntroView;
  private creditsView?: CreditsView;
  private manualPlay: HTMLButtonElement;

  constructor() {
    this.onAutoplayFailed = this.onAutoplayFailed.bind(this);
    this.onAutoplaySucceeded = this.onAutoplaySucceeded.bind(this);
    this.handleClickManualPlayButton = this.handleClickManualPlayButton.bind(
      this,
    );
    this.onStepUpdated = this.onStepUpdated.bind(this);
    window.addEventListener(AppEvents.Step, this.onStepUpdated);
    window.addEventListener(ScreenEvents.AutoplayFailed, this.onAutoplayFailed);
    window.addEventListener(
      ScreenEvents.AutoplaySucceeded,
      this.onAutoplaySucceeded,
    );

    this.manualPlay = document.querySelector("._js_manual-play");
    this.manualPlay.addEventListener("click", this.handleClickManualPlayButton);

    this.hudView = new HUDView();
    this.introView = new IntroView();
  }

  private onAutoplayFailed(): void {
    this.manualPlay.style.display = "block";
    console.warn("Autoplay failed, show manual play button");
  }

  private onAutoplaySucceeded(): void {
    // this.manualPlay.style.display = "none";
  }

  private handleClickManualPlayButton(): void {
    this.manualPlay.style.display = "none";
    const resumePlaybackEvent = new CustomEvent(UIEvents.ResumePlayback);
    window.dispatchEvent(resumePlaybackEvent);
  }

  private onStepUpdated({ detail }: IStepEvent) {
    if (detail === Step.Loaded) {
      this.introView.onExperienceLoaded();
    }
    if (detail === Step.Ready) {
      this.introView.onExperienceReady();
    }
    if (detail === Step.Enter) {
      this.introView.kill();
      delete this.introView;
    }
    if (detail === Step.PostShow) {
      this.creditsView = new CreditsView();
    }
    if (detail === Step.PostCredits) {
      delete this.creditsView;
    }
  }
}
