import { VideoMetadata } from "../videoData";
import { ICountdown } from "../TimelineController";
import { Step } from "../../client";

export enum TimelineEvents {
  CountdownUpdated = "countdownupdated",
  TimelineStarted = "timelinestarted",
  TimelineEnded = "timelineended",
  IntroComplete = "introcomplete",
  CountdownComplete = "countdowncomplete",
}

export enum UIEvents {
  Start = "userstart",
  ResumePlayback = "resumeplayback",
  UpdateRoomData = "updateroomdata",
  UpdateSubtitle = "updatesubtitle",
  UpdateCameraPreset = "updatecamerapreset",
  CreditsComplete = "creditscomplete",
  MuteUnmute = "muteunmute",
  UserControls = "usercontrols",
}

export enum ScreenEvents {
  QueueScreen = "queuescreen",
  Ending = "videoscreenending",
  Buffering = "videoscreenbuffering",
  Playing = "videoscreenplaying",
  AutoplayFailed = "autoplayfailed",
  AutoplaySucceeded = "autoplaysucceeded",
  GoTo = "gotoscreen",
  GoToHalfway = "gotohalfway",
  GoToComplete = "gotoscreencomplete",
}

export enum AppEvents {
  Step = "stepchange",
  IntroReady = "introready",
  Ready = "ready",
  Loaded = "loadedapp",
}

export interface IUserControlsEvent extends CustomEvent {
  detail: boolean;
}

export interface IMuteUnmuteEvent extends CustomEvent {
  detail: boolean;
}

export interface IStepEvent extends CustomEvent {
  detail: Step;
}

export interface IUpdateRoomDataEvent extends CustomEvent {
  detail: {
    data: VideoMetadata;
    index: number;
  } | null;
}

export interface IUpdateSubtitleEvent extends CustomEvent {
  detail: string | null;
}

export interface ICountdownEvent extends CustomEvent {
  detail: ICountdown;
}

export interface IGoToScreenEvent extends CustomEvent {
  detail: number;
}

export interface IQueueScreenEvent extends CustomEvent {
  detail: { index: number; switchImmidiate: boolean };
}

export interface IUpdateCameraPresetEvent extends CustomEvent {
  detail: number | null;
}
