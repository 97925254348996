import { gsap } from "gsap/all";
import uiSfx from "../UISfx";

import { UIEvents } from "../../util/Events";

export default class CreditsView {
  private el: HTMLElement;
  private innerEl: HTMLElement;
  private backdropEl: HTMLElement;

  constructor() {
    this.onComplete = this.onComplete.bind(this);
    this.start = this.start.bind(this);

    this.el = document.querySelector("._js_credits-view");
    this.innerEl = document.querySelector("._js_credits-view__inner");
    this.backdropEl = document.querySelector("._js_credits-view__backdrop");

    this.innerEl.style.transform = "translate3d(0, 0, 0)";
    this.backdropEl.style.display = "block";
    gsap.fromTo(this.backdropEl, { opacity: 0 }, { opacity: 1, duration: 1 });
    setTimeout(this.start, 3333);
  }

  private start() {
    this.innerEl.addEventListener("transitionend", this.onComplete);
    this.innerEl.style.transform = "translate3d(0, -100%, 0)";
    uiSfx.play("credits");
  }

  private onComplete() {
    gsap.fromTo(
      this.backdropEl,
      { opacity: 1 },
      {
        opacity: 0,
        duration: 1,
        onComplete: () => (this.backdropEl.style.display = "none"),
      },
    );
    this.innerEl.removeEventListener("transitionend", this.onComplete);
    const onCompleteEvent = new CustomEvent(UIEvents.CreditsComplete);
    window.dispatchEvent(onCompleteEvent);
  }
}
