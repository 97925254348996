import {
  Vector3,
  Shape,
  ExtrudeBufferGeometry,
  MeshBasicMaterial,
  Mesh,
} from "three";
import { BIRD_SETTINGS as SETTINGS, BIRD_SETTINGS } from "../../CONSTANTS";
import { convertToRange } from "../lib/maths";

const Bird = (initPos = new Vector3()) => {
  const pos = new Vector3().copy(initPos);
  const vel = new Vector3();
  const acc = new Vector3();

  const tmp = new Vector3();
  const tmp2 = new Vector3();

  const steerRand = (Math.random() - 0.5) * (BIRD_SETTINGS.maxSteer * 0.3);
  const velRand = (Math.random() - 0.5) * (BIRD_SETTINGS.maxVel * 0.3);

  let mesh;

  const createGeometry = () => {
    const shape = new Shape();
    shape.moveTo(0, 0.1);
    shape.quadraticCurveTo(0.15, 0.15, 0.12, 0);
    shape.quadraticCurveTo(0, -0.25, 0.3, -0.65);
    shape.lineTo(-0.3, -0.65);
    shape.quadraticCurveTo(0, -0.2, -0.12, 0);
    shape.quadraticCurveTo(-0.15, 0.15, 0, 0.1);

    const geom = new ExtrudeBufferGeometry(shape, {
      steps: 1,
      depth: 0.1,
      bevelEnabled: true,
      bevelThickness: 0.1,
      bevelSize: 0.05,
      bevelSegments: 1,
    });
    // geom.scale(0.7, 0.7, 0.7);
    geom.scale(0.2, 0.2, 0.2);
    geom.rotateX(Math.PI * 0.5);
    return geom;
  };

  const createMesh = geometry => {
    const material = new MeshBasicMaterial({ color: 0x000000 });
    const mesh = new Mesh(geometry, material);
    mesh.position.copy(pos);
    mesh.castShadow = true;
    mesh.receiveShadow = false;
    mesh.fog = false;

    return mesh;
  };

  const seek = target => {
    tmp.copy(target).sub(pos);

    // when its near the target, slow it down
    if (tmp.length() < 2.5) {
      const scale = convertToRange(
        tmp.length(),
        [0, 1.5],
        [0, SETTINGS.maxVel + velRand],
      );
      tmp.normalize().multiplyScalar(scale);
    } else {
      tmp.normalize().multiplyScalar(SETTINGS.maxVel + velRand);
    }
    tmp.sub(vel).clampLength(0, SETTINGS.maxSteer + steerRand); // Steer
    return tmp;
  };

  const seperate = siblings => {
    let count = 0;
    tmp.set(0, 0, 0);

    siblings.forEach(s => {
      const dist = pos.distanceTo(s.pos);
      if (dist <= SETTINGS.seperation && dist > 0) {
        tmp2
          .copy(pos)
          .sub(s.pos)
          .normalize();
        tmp.add(tmp2); //sum
        count++;
      }
    });
    if (count === 0) return tmp.set(0, 0, 0);

    tmp
      .divideScalar(count)
      .multiplyScalar(SETTINGS.maxVel + velRand)
      .sub(vel)
      .clampLength(0, SETTINGS.maxSteer + steerRand);
    return tmp;
  };

  const align = siblings => {
    let count = 0;
    tmp.set(0, 0, 0);

    siblings.forEach(s => {
      const dist = pos.distanceTo(s.pos);
      if (dist <= SETTINGS.viewDist) {
        tmp.add(s.vel); //sum
        count++;
      }
    });
    if (count === 0) return tmp.set(0, 0, 0);

    tmp
      .divideScalar(count)
      .multiplyScalar(SETTINGS.maxVel + velRand)
      .sub(vel)
      .clampLength(0, SETTINGS.maxSteer + steerRand);
    return tmp;
  };

  const cohese = siblings => {
    let count = 0;
    tmp.set(0, 0, 0);

    siblings.forEach(s => {
      const dist = pos.distanceTo(s.pos);
      if (dist <= SETTINGS.viewDist) {
        tmp.add(s.pos); //sum
        count++;
      }
    });

    if (count === 0) return tmp.copy(0, 0, 0);

    tmp.divideScalar(count);
    return seek(tmp);
  };

  const applyBehaviors = (target, siblings) => {
    applyForce(seek(target).multiplyScalar(0.1));
    applyForce(align(siblings).multiplyScalar(0.08));
    applyForce(cohese(siblings).multiplyScalar(0.02));
    applyForce(seperate(siblings).multiplyScalar(0.25));
  };

  const applyForce = vec => {
    acc.add(vec);
  };

  const update = correction => {
    acc.multiplyScalar(correction);
    vel.add(acc);
    vel.clampLength(0, SETTINGS.maxVel + velRand * correction);

    mesh.position.copy(pos);
    mesh.lookAt(tmp.copy(pos).add(tmp2.copy(vel).normalize()));

    pos.add(vel);

    acc.set(0, 0, 0);
  };

  const geometry = createGeometry();
  mesh = createMesh(geometry);

  return { update, mesh, applyBehaviors, applyForce, pos, vel };
};

export default Bird;
