import { Cylindrical, MeshBasicMaterial, Object3D } from "three";
import SimplexNoise from "simplex-noise";

const noiseX = new SimplexNoise("x1");
const noiseY = new SimplexNoise("y1");
const noiseZ = new SimplexNoise("z1");

class Drone extends Object3D {
  private rotors: Object3D[] = [];
  private group = Object3D;
  private time = 0;
  private noiseOffset = Math.random() * 100;

  constructor(group: Object3D) {
    super();
    this.group = group.clone();
    this.add(this.group);

    this.rotors = this.getObjectByName("Rotorgroup").children;
    // console.log(rotors);
    // [...rotors.children].forEach(rotor => {
    //   const rotorPositionGroup = new Object3D();
    //   rotorPositionGroup.position.copy(rotor.position);

    //   rotor.parent.remove(rotor);
    //   rotorPositionGroup.add(rotor);
    //   this.group.add(rotorPositionGroup);

    //   rotor.position.set(0, 0, 0);
    //   this.rotors.push(rotor);
    // });
  }

  public update(delta: number, cylindrical: Cylindrical): void {
    this.time += delta;
    this.position.setFromCylindrical(cylindrical);

    this.position.x +=
      noiseX.noise2D(this.noiseOffset, this.time * 0.002) * 0.3;
    this.position.y +=
      0.5 + noiseY.noise2D(this.time * 0.004, this.noiseOffset) * 0.5;
    this.position.z +=
      noiseZ.noise2D(this.noiseOffset, this.time * 0.002) * 0.2;

    this.rotation.x = Math.sin(this.time * 0.02 + this.noiseOffset) * 0.075;
    this.rotation.z = Math.cos(this.time * 0.033 + this.noiseOffset) * 0.075;
    this.rotation.y = Math.sin(this.time * 0.025 + this.noiseOffset) * 0.075;

    this.rotors.forEach(rotor => (rotor.rotation.y = this.time * 1.2));
  }
}

export default Drone;
