import {
  AudioContext as THREEAudioContext,
  PositionalAudio,
  Object3D,
  AudioListener,
  Audio,
} from "three";
import { gsap } from "gsap/all";
import {
  ROLLOFF,
  REF_DIST,
  AMBIENT_VOLUME,
  SCREEN_VOLUME,
  INTRO_TRACK_VOLUME,
  DRONE_VOLUME,
  MAX_AMBIENT_AUDIO,
} from "../../CONSTANTS";
import { audioLoader } from "../util/Loaders";

import { audioData, AudioData, introAudioTrack } from "../audioData";
import VideoScreen from "./VideoScreen";
import {
  UIEvents,
  IMuteUnmuteEvent,
  AppEvents,
  IStepEvent,
} from "../util/Events";
import { Step } from "../../client";
import droneAudioSrc from "../../audio/drone.mp3";

type TLevel = "low" | "high";

const audioContext = THREEAudioContext.getContext();

export default class AmbientAudio extends Object3D {
  public ambientListener: AudioListener = new AudioListener();
  public screenListener: AudioListener = new AudioListener();
  private ambientAudio: Object3D[] = [];
  private videoAudio: Object3D[] = [];
  private droneAudio: Audio;
  private introAudio: Audio;
  private activeVideoScreens: VideoScreen[] = [];
  private droneControl = { volume: DRONE_VOLUME, playbackRate: 1 };
  private ambientVolumeControl = { value: 0 };
  private screenVolumeControl = { value: 0 };
  private introVolumeControl = { value: 0 };
  private _isMuted = false;
  private _ambientVolume: TLevel = "high";
  private _dronePitch: TLevel = "low";

  constructor() {
    super();

    this.onUserStart = this.onUserStart.bind(this);
    this.onStepUpdated = this.onStepUpdated.bind(this);
    this.ambientListener.setMasterVolume(0);
    window.addEventListener(AppEvents.Step, this.onStepUpdated);

    this.addDroneAudio();
    this.addIntroAudio();
  }

  public init(): void {
    if (audioContext.state === "suspended") {
      window.addEventListener(UIEvents.Start, this.onUserStart);
    } else {
      this.onUserStart();
    }

    window.addEventListener(
      UIEvents.MuteUnmute,
      ({ detail }: IMuteUnmuteEvent) => (this.isMuted = detail),
    );
  }

  private get isMuted(): boolean {
    return this._isMuted;
  }

  private set isMuted(value: boolean) {
    this._isMuted = value;
    gsap.killTweensOf(this.ambientVolumeControl);
    gsap.killTweensOf(this.screenVolumeControl);
    if (value) {
      this.screenVolumeControl.value = 0;
      this.ambientVolumeControl.value = 0;
    } else {
      this.screenVolumeControl.value = 1;
      this.ambientVolumeControl.value = this.ambientVolume === "low" ? 0.5 : 1;
    }
    this.ambientListener.setMasterVolume(this.ambientVolumeControl.value);
    this.screenListener.setMasterVolume(this.screenVolumeControl.value);
  }

  private get ambientVolume(): TLevel {
    return this._ambientVolume;
  }

  private set ambientVolume(value: TLevel) {
    if (this.ambientVolume === value) return;
    this._ambientVolume = value;
    if (this.isMuted) return;
    gsap.killTweensOf(this.ambientVolumeControl);
    gsap.to(this.ambientVolumeControl, {
      value: value === "low" ? 0.5 : 1,
      duration: 5,
      ease: "sine.inOut",
      onUpdate: () => {
        this.ambientListener.setMasterVolume(this.ambientVolumeControl.value);
      },
    });

    if (value === "high") {
      this.introAudio.setLoop(true);
      this.introAudio.play();
    }
    gsap.to(this.introVolumeControl, {
      value: value === "low" ? 0 : INTRO_TRACK_VOLUME,
      duration: 5,
      ease: "sine.inOut",
      onUpdate: () => {
        this.introAudio.setVolume(this.introVolumeControl.value);
      },
      onComplete: () => {
        if (value === "low") {
          try {
            this.introAudio.stop();
          } catch (error) {
            console.warn(error);
          }
        }
      },
    });
  }

  public get dronePitch(): TLevel {
    return this._dronePitch;
  }

  public set dronePitch(value: TLevel) {
    if (this.dronePitch === value) return;
    this._dronePitch = value;

    gsap.killTweensOf(this.droneControl);
    gsap.to(this.droneControl, {
      playbackRate: value === "low" ? 1 : 1.2,
      volume: value === "low" ? DRONE_VOLUME : DRONE_VOLUME + 0.15,
      duration: 0.66,
      ease: "sine.inOut",
      onUpdate: () => {
        this.droneAudio.setPlaybackRate(this.droneControl.playbackRate);
        this.droneAudio.setVolume(this.droneControl.volume);
      },
    });
  }

  private onStepUpdated({ detail }: IStepEvent) {
    if (detail === Step.Show || detail === Step.PostShow) {
      this.ambientVolume = "low";
    } else {
      this.ambientVolume = "high";
    }
    if (detail === Step.PostShow) {
      this.setupAudioScene(true);
    }
  }

  private createNewAudio({
    isPositional = true,
    listener,
  }: {
    isPositional?: boolean;
    listener: AudioListener;
  }): Audio | PositionalAudio {
    const AudioType = isPositional ? PositionalAudio : Audio;
    const audio = new AudioType(listener);
    if (isPositional) {
      (audio as PositionalAudio).setRolloffFactor(ROLLOFF);
      (audio as PositionalAudio).setRefDistance(REF_DIST);
    }
    audio.setVolume(1);
    audio.setLoop(true);
    return audio;
  }

  private onUserStart(): void {
    this.requestPermission();
    this.setupAudioScene();

    if (this.isMuted) return;
    gsap.to(this.ambientVolumeControl, {
      value: 1,
      duration: 5,
      ease: "sine.inOut",
      onUpdate: () => {
        this.ambientListener.setMasterVolume(this.ambientVolumeControl.value);
      },
    });
    gsap.to(this.screenVolumeControl, {
      value: 1,
      duration: 5,
      ease: "sine.inOut",
      onUpdate: () => {
        this.screenListener.setMasterVolume(this.ambientVolumeControl.value);
      },
    });
  }

  private requestPermission() {
    if (audioContext.state === "suspended") {
      audioContext.resume();
    }
  }

  private setupAudioScene(stagger = false) {
    const audios = [...audioData].slice(0, MAX_AMBIENT_AUDIO);
    audios.forEach((data: AudioData, index: number) => {
      if (stagger) {
        setTimeout(() => {
          this.addPositionalAudio(data);
        }, index * 2000);
      } else {
        this.addPositionalAudio(data);
      }
    });
  }

  public addScreenAudio(videoScreen: VideoScreen): void {
    const audio = this.createNewAudio({ listener: this.screenListener });
    audio.setVolume(SCREEN_VOLUME);

    const parent = new Object3D();
    videoScreen.screenMesh.getWorldPosition(parent.position);

    parent.add(audio);
    this.add(parent);
    audio.setMediaElementSource(videoScreen.video);

    this.activeVideoScreens.push(videoScreen);
    this.videoAudio.push(parent);
  }

  public removeScreenAudio(videoScreen: VideoScreen): void {
    const index = this.activeVideoScreens.indexOf(videoScreen);

    this.activeVideoScreens.splice(index, 1);
    const toRemove = this.videoAudio.splice(index, 1)[0];
    if ((toRemove.children[0] as PositionalAudio).hasPlaybackControl) {
      try {
        (toRemove.children[0] as PositionalAudio).stop();
      } catch (error) {
        console.warn(error);
      }
    }
    this.remove(toRemove);
  }

  private shiftOldestAudio() {
    const toRemove = this.ambientAudio.shift();
    if ((toRemove.children[0] as PositionalAudio).hasPlaybackControl) {
      try {
        (toRemove.children[0] as PositionalAudio).setLoop(false);
        (toRemove.children[0] as PositionalAudio).stop();
      } catch (error) {
        console.warn(error);
      }
    }
    this.remove(toRemove);
  }

  public addPositionalAudio(data: AudioData): void {
    const audio = this.createNewAudio({ listener: this.ambientListener });

    if (this.ambientAudio.length > MAX_AMBIENT_AUDIO) {
      this.shiftOldestAudio();
    }

    const parent = new Object3D();
    parent.position.copy(data.position);
    parent.add(audio);
    this.add(parent);
    this.ambientAudio.push(parent);

    audioLoader.load(data.src, buffer => {
      audio.setBuffer(buffer);
      audio.play();

      const control = { value: 0 };
      gsap.to(control, {
        value: AMBIENT_VOLUME * data.volumeWeight || 1,
        duration: 5,
        ease: "sine.inOut",
        onUpdate: () => audio.setVolume(control.value),
      });
    });
  }

  private addDroneAudio(): void {
    this.droneAudio = this.createNewAudio({
      listener: this.ambientListener,
      isPositional: false,
    }) as Audio;

    audioLoader.load(droneAudioSrc, buffer => {
      this.droneAudio.setBuffer(buffer);
      this.droneAudio.play();

      const control = { value: 0 };
      gsap.to(control, {
        value: DRONE_VOLUME,
        duration: 5,
        ease: "sine.inOut",
        onUpdate: () => this.droneAudio.setVolume(control.value),
      });
    });
  }

  private addIntroAudio(): void {
    this.introAudio = this.createNewAudio({
      listener: this.screenListener,
    }) as Audio;

    const parent = new Object3D();
    parent.position.set(0, 4, 6);
    parent.add(this.introAudio);
    this.add(parent);
    this.ambientAudio.push(parent);

    audioLoader.load(introAudioTrack, buffer => {
      this.introAudio.setBuffer(buffer);
      this.introAudio.play();

      gsap.to(this.introVolumeControl, {
        value: INTRO_TRACK_VOLUME,
        duration: 5,
        ease: "sine.inOut",
        onUpdate: () =>
          this.introAudio.setVolume(this.introVolumeControl.value),
      });
    });
  }
}
