export default class ViewerCount {
  private el: HTMLElement;

  constructor(el: HTMLElement) {
    this.el = el;
  }

  public set count(value: number | null) {
    requestAnimationFrame(() => {
      if (value === null) {
        this.el.style.display = "none";
        this.el.innerHTML = "";
      } else {
        this.el.innerHTML = `${new Intl.NumberFormat().format(value)}`;
        this.el.style.display = "block";
      }
    });
  }

  public kill(): void {
    this.el.style.display = "none";
  }
}
