export default class Subtitles {
  private el: HTMLElement;
  private innerEl: HTMLElement;

  constructor(el: HTMLElement) {
    this.el = el;
    this.innerEl = this.el.querySelector("._js_subtitles__subtitle");
  }

  public set subtitle(value: string | null) {
    requestAnimationFrame(() => {
      if (value === null) {
        this.el.style.display = "none";
        this.innerEl.innerHTML = "";
      } else {
        this.innerEl.innerHTML = value;
        this.el.style.display = "block";
      }
    });
  }

  public kill(): void {
    this.el.style.display = "none";
  }
}
