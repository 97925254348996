import { convertToRange } from "../../lib/maths";
import { throttle as _throttle } from "lodash";
import ticker from "../../util/Ticker";

import { app } from "../../../client";

const SPRING = 0.1;
const BOUNCE_FRICTION = 0.125;
const EASE = 0.04;
const WANDER = 5;

class ViewfinderPresetIndicator {
  private el: SVGElement;
  private currentOffset = { x: 0, y: 0 };
  private vectorOffset = { x: 0, y: 0 };
  private targetOffset = { x: 0, y: 0 };
  private isActive = false;

  constructor(el: SVGElement) {
    this.el = el;
    this.onMouseMove = _throttle(this.onMouseMove.bind(this), 33);
    this.update = this.update.bind(this);
  }

  public set isVisible(value: boolean) {
    if (value === true) {
      this.el.style.stroke = "#FF0000";
      this.el.style.strokeOpacity = "1";
      this.isActive = true;

      window.addEventListener("mousemove", this.onMouseMove);
    } else {
      window.removeEventListener("mousemove", this.onMouseMove);
      this.el.style.stroke = "#000";
      this.el.style.strokeOpacity = "0.5";

      this.targetOffset.x = 0;
      this.targetOffset.y = 0;
      this.isActive = false;
      if (!ticker.hasCallback(this.update)) {
        ticker.addCallback(this.update);
      }
    }
  }

  private onMouseMove({ clientX, clientY }: MouseEvent) {
    this.targetOffset.x = convertToRange(
      clientX,
      [0, app.windowSize.width],
      [-WANDER, WANDER],
    );
    this.targetOffset.y = convertToRange(
      clientY,
      [0, app.windowSize.height],
      [-WANDER, WANDER],
    );

    if (!ticker.hasCallback(this.update)) {
      ticker.addCallback(this.update);
    }
  }

  private update(delta = 1) {
    const ease = this.isActive ? EASE : SPRING;

    this.vectorOffset.x += (this.targetOffset.x - this.currentOffset.x) * ease;
    this.vectorOffset.y += (this.targetOffset.y - this.currentOffset.y) * ease;

    this.currentOffset.x += this.vectorOffset.x *= 1 - BOUNCE_FRICTION;
    this.currentOffset.y += this.vectorOffset.y *= 1 - BOUNCE_FRICTION;

    this.el.style.transform = `translate(${this.currentOffset.x}px, ${this.currentOffset.y}px)`;

    if (
      Math.abs(this.targetOffset.y - this.currentOffset.y) < 0.1 &&
      Math.abs(this.targetOffset.x - this.currentOffset.x) < 0.1
    ) {
      ticker.removeCallback(this.update);
    }
  }
}

export default ViewfinderPresetIndicator;
