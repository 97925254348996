import { INTRO_VIDEO_SRC } from "../../../CONSTANTS";
import { MartineRosePanopticonApp } from "../../../client";
import Hls from "hls.js";
import { AppEvents } from "../../util/Events";

export default class IntroVideo {
  private video: HTMLVideoElement;
  private hls?: Hls;
  private pollInterval: any;

  constructor(el: HTMLVideoElement) {
    this.video = el;

    this.onReady = this.onReady.bind(this);
    this.onPlaying = this.onPlaying.bind(this);
    this.restartVideo = this.restartVideo.bind(this);

    if (MartineRosePanopticonApp.videoSupport === "hls") {
      this.hls = new Hls();
      this.hls.loadSource(INTRO_VIDEO_SRC);
      this.hls.attachMedia(this.video);
    } else if (MartineRosePanopticonApp.videoSupport === "native") {
      this.video.src = INTRO_VIDEO_SRC;
    }
    this.video.addEventListener("playing", this.onPlaying);
    this.video.addEventListener("playing", this.onReady);
    this.restartVideo();

    const playPromise = this.video.play();
    if (playPromise !== undefined) {
      playPromise.catch(() => {
        // if can't autoplay, start the experience loading
        this.video.removeEventListener("playing", this.onReady);
        this.onReady();
      });
    }
  }

  private restartVideo() {
    this.video.pause();
    this.video.currentTime = 0;
    this.video.play();
  }

  private onPlaying() {
    const pollCurrentTime = () => {
      if (this.video.duration - this.video.currentTime < 0.1) {
        clearInterval(this.pollInterval);
        this.restartVideo();
      }
    };
    clearInterval(this.pollInterval);
    this.pollInterval = setInterval(pollCurrentTime, 16);
  }

  private onReady() {
    this.video.removeEventListener("playing", this.onReady);
    const introReadyEvent = new CustomEvent(AppEvents.IntroReady);
    window.dispatchEvent(introReadyEvent);
  }

  public kill(): void {
    requestAnimationFrame(() => {
      clearInterval(this.pollInterval);
      this.video.removeEventListener("playing", this.onPlaying);
      this.video.removeEventListener("playing", this.onReady);
      this.video.style.display = "none";
      this.video.pause();
      this.hls.detachMedia();
      this.hls.destroy();
      delete this.hls;
    });
  }
}
