import { UIEvents } from "../../util/Events";

type DisplayMode = "initializing" | "preshow" | "live";
import { app } from "../../../client";

export default class StartButton {
  private el: HTMLButtonElement;

  constructor(el: HTMLButtonElement) {
    this.el = el;
    this.onClick = this.onClick.bind(this);
    this.el.addEventListener("click", this.onClick);
  }

  public set displayMode(value: DisplayMode) {
    requestAnimationFrame(() => {
      if (value === "initializing") {
        this.el.style.display = "block";
        this.el.innerHTML = "Almost there";
        this.el.disabled = true;
      }
      if (value === "preshow") {
        this.el.style.display = "block";
        this.el.innerHTML =
          app.timelineController.mode === "legacy" ? "Enter" : "Enter Presentation";
        this.el.disabled = false;
      }
      if (value === "live") {
        this.el.style.display = "block";
        this.el.innerHTML = "Enter LIVE Presentation";
        this.el.disabled = false;
      }
    });
  }

  private onClick() {
    const userStartEvent = new CustomEvent(UIEvents.Start);
    window.dispatchEvent(userStartEvent);
  }

  public kill(): void {
    requestAnimationFrame(() => {
      this.el.removeEventListener("click", this.onClick);
      this.el.style.display = "none";
    });
  }
}
