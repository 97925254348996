import Hls from "hls.js";
import TimelineController from "./scripts/TimelineController";
import UIController from "./scripts/ui/UIController";
import WebGLView from "./scripts/WebGLView";
import { Renderer } from "three";
import { AppEvents, TimelineEvents, UIEvents } from "./scripts/util/Events";
import { debounce as _debounce } from "lodash";

export let app;

const video = document.createElement("video");

export enum Step {
  PreInit,
  Loading,
  Loaded,
  Ready,
  Enter,
  PreShow,
  Show,
  PostShow,
  PostCredits,
  Legacy,
}

export class MartineRosePanopticonApp {
  public timelineController: TimelineController;
  private uiController?: UIController;
  public windowSize = { width: window.innerWidth, height: window.innerHeight };
  private webGlView?: WebGLView;
  private _step = Step.PreInit;

  static get deviceGrade(): "low" | "medium" | "high" | "unknown" {
    if ("deviceMemory" in navigator && "hardwareConcurrency" in navigator) {
      if (navigator.hardwareConcurrency >= 4 && navigator.deviceMemory >= 6) {
        return "high";
      }
      if (navigator.hardwareConcurrency >= 2 && navigator.deviceMemory >= 4) {
        return "medium";
      }
      return "low";
    }

    if ("hardwareConcurrency" in navigator) {
      if (navigator.hardwareConcurrency >= 4) {
        return "high";
      }
      if (navigator.hardwareConcurrency >= 2) {
        return "medium";
      }
      return "low";
    }

    return "unknown";
  }

  static get isSafari(): boolean {
    return (
      navigator.userAgent.toLowerCase().indexOf("safari") > -1 &&
      navigator.userAgent.toLowerCase().indexOf("chrome") === -1
    );
  }

  static get videoSupport(): string | boolean {
    return Hls.isSupported()
      ? "hls"
      : video.canPlayType("application/vnd.apple.mpegurl")
      ? "native"
      : false;
  }

  public get webGLContext(): WebGLRenderingContext | undefined {
    return this.webGlView && this.webGlView.context;
  }

  public get renderer(): Renderer | undefined {
    return this.webGlView && this.webGlView.renderer;
  }

  public get step(): Step {
    return this._step;
  }

  public set step(value: Step) {
    this._step = value;
    const stepEvent = new CustomEvent(AppEvents.Step, { detail: value });
    window.dispatchEvent(stepEvent);
  }

  constructor() {
    this.onResize = _debounce(this.onResize.bind(this), 333);

    window.addEventListener(AppEvents.IntroReady, () => {
      this.step = Step.Loading;
    });
    window.addEventListener(AppEvents.Loaded, () => {
      this.step = Step.Loaded;
    });
    window.addEventListener(AppEvents.Ready, () => {
      this.step = Step.Ready;
    });
    window.addEventListener(UIEvents.Start, () => {
      this.step = Step.Enter;
    });
    window.addEventListener(TimelineEvents.IntroComplete, () => {
      if (window.location.search.indexOf("credits") > -1) {
        this.step = Step.PostShow;
      } else {
        this.step = Step.PreShow;
      }
    });
    window.addEventListener(TimelineEvents.TimelineStarted, () => {
      this.step = Step.Show;
    });
    window.addEventListener(TimelineEvents.TimelineEnded, () => {
      this.step = Step.PostShow;
    });
    window.addEventListener(UIEvents.CreditsComplete, () => {
      this.step = Step.PostCredits;
    });
    window.addEventListener("resize", this.onResize);

    this.timelineController = new TimelineController();
  }

  private onResize() {
    this.windowSize = { width: window.innerWidth, height: window.innerHeight };
  }

  public async init(): Promise<void> {
    await this.timelineController.init();
    this.uiController = new UIController();
    this.webGlView = new WebGLView(document.querySelector(".canvas"));
  }
}

const kickIt: () => void = () => {
  app = new MartineRosePanopticonApp();
  app.init();

  console.log(
    `%c
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMWNX0kxxddxxOKXWMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMWWWWNKxl:,'''''''',cd0WMMMMMMMMMMMMMM
MMMMMMMMMWXOxdoodo:,,,,'........''ckNMMMMMMMMMMMMM
MMMMMMMMXx:'...',,,;;;;;,,,''''''.,ckXWMMMMMMMMMMM
MMMMMMWNO:....',,,;;'....,,,..'',,';ccd0NWMMMMMMMM
MMMMMNOl;,'...',,,'''......'....',;;:;;:o0NMMMMMMM
MMMMXo,''',,'..','';c:'';;'......';;;;;::o0WMMMMMM
MMMMO;',,'',;'.'',,,;;,,;;;,,,'..,,',;::cdKMMMMMMM
MMMMXl,,;,'',;,'.'',,,,;:c:::;'.','';;:lxXWMMMMMMM
MMMMMXd;,;,'',;:,.'''',;,,,,;;;,,,,;;co0NMMMMMMMMM
MMMMMMNOc,'..'',:;,'',;;:cccc:,'.',:lkXWMMMMMMMMMM
MMMMMMMMXl'''...',;::::cllc:;'..,;ccoKMMMMMMMMMMMM
MMMMMMMMWx'..'''...';::c::;,'.';cc:;:OWMMMMMMMMMMM
MMMMMMMMM0;....''''..',;;;;'.,;::;;,:0MMMMMMMMMMMM
MMMMMMMMMWd.......''''..'',,,;;;;;;,lXMMMMMMMMMMMM
MMMMMMMMMMXd'.......''''''''',;;;;;;cx0NMMMMMMMMMM
MMMMMMMMMMMNkc'.......'''''''',,;::;lOXWMMMMMMMMMM
MMMMMMMMMMMMW0l;''.'',,,''.....,:c:oKWMMMMMMMMMMMM
MMMMMMMMMMWNklclodocclc:;,,:ddlodxOXWMMMMMMMMMMMMM
MMMMMMMMWXOocldOXNOllkkl;;;xNMMWWWMMMMMMMMMMMMMMMM
MMMMMMMMMWNXXXWMNOdx0WXo;:;dNMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMWNNWMMNo;:;oXMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMNd;:;oXMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMNOdxdkNMMMMMMMMMMMMMMMMMMMMM`,
    "background: #d9022a;",
  );
  console.log(
    `%c
Love from,
Martine Rose, International Magic and Jack Wild.
Jan 2021.`,
    "font-size: 30px; font-family: Brush Script MT",
  );
};

if (document.addEventListener) {
  document.addEventListener("DOMContentLoaded", kickIt);
} else {
  (window as any).attachEvent("onload", kickIt);
}
