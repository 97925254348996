import { pad } from "../../lib/misc";

export default class CameraPreset {
  private el: HTMLElement;
  private indexEl: HTMLSpanElement;

  constructor(el: HTMLElement) {
    this.el = el;
    this.indexEl = this.el.querySelector("._js_camera-preset__index");
  }

  public set index(value: number) {
    if (value === null) {
      this.el.style.display = "none";
    } else {
      this.el.style.display = "block";
      this.indexEl.innerHTML = pad(value);
    }
  }
}
