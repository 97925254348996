import { Vector3 } from "three";

import ambientSrcTwo from "../audio/3-sound-footsteps.mp3"; // Footsteps
import ambientSrcThree from "../audio/0-sound-water.mp3"; // water
import ambientSrcFour from "../audio/2-sound-kids.mp3"; // Kids
import ambientSrcFive from "../audio/4-sound-party.mp3"; // Party
import ambientSrcSix from "../audio/1-sound-birds.mp3"; //birds
import ambientSrcSeven from "../audio/5-building-hum.mp3"; //building hum
import ambientSrcEight from "../audio/7-radio.mp3"; //radio
import ambientSrcNine from "../audio/8-speech-practice.mp3"; //speech
import ambientSrcTen from "../audio/9-traffic.mp3"; //traffic
import ambientSrcEleven from "../audio/10-tv.mp3"; //tv
import introSrc from "../audio/7-catwalk.mp3";

export type AudioData = {
  name: string;
  src: string;
  position: Vector3;
  volumeWeight?: number;
};

export const introAudioTrack = introSrc;

// structure is 19m tall
export const audioData: AudioData[] = [
  {
    name: "water",
    src: ambientSrcThree,
    position: new Vector3(0, 1, 0),
    volumeWeight: 1,
  },
  {
    name: "kids",
    src: ambientSrcFour,
    position: new Vector3(14, 3, 0),
    volumeWeight: 0.7,
  },
  {
    name: "tv",
    src: ambientSrcEleven,
    position: new Vector3(6, 5, -6),
    volumeWeight: 0.8,
  },
  {
    name: "footsteps",
    src: ambientSrcTwo,
    position: new Vector3(8, 6, 5),
    volumeWeight: 0.8,
  },
  {
    name: "speech",
    src: ambientSrcNine,
    position: new Vector3(5, 14, -5),
    volumeWeight: 0.9,
  },
  {
    name: "hum",
    src: ambientSrcSeven,
    position: new Vector3(0, 14, 0),
    volumeWeight: 0.8,
  },
  {
    name: "birds",
    src: ambientSrcSix,
    position: new Vector3(0, 20, 0),
    volumeWeight: 0.8,
  },
];

export type CameraPresetUtterance = {
  key?: string;
  subtitle?: string;
  duration?: number;
};

export const cameraPresetUtterances: (CameraPresetUtterance | null)[] = [
  null,
  {
    key: "1-what_will_our",
    subtitle: "What will our reunion look like when it finally takes place?",
    duration: 4000,
  },
  {
    key: "2-how_will_we_remember",
    subtitle: "How will we remember the way we lived?",
    duration: 3750,
  },
  {
    key: "3-everywhere_on_earth",
    subtitle:
      "Everywhere on earth the outside came crashing through our doors.",
    duration: 4250,
  },
  {
    key: "4-the_places_where_we_work",
    subtitle: "The places where we work –",
    duration: 2500,
  },
  {
    key: "5-eat_lunch_exercise",
    subtitle: "eat lunch, exercise, get dressed and fall in love –",
    duration: 4250,
  },
  {
    key: "6-rebuilt_themselves",
    subtitle: "rebuilt themselves inside our homes.",
    duration: 3250,
  },
  {
    key: "7_whole_city_in_four_walls",
    subtitle: "A whole city in four walls.",
    duration: 3250,
  },
  {
    key: "8-and_yet_for_all_the",
    subtitle: "And yet for all the similarities...",
    duration: 3000,
  },
  {
    key: "9-hazmat_shopping_trips",
    subtitle: "hazmat shopping trips and brain fog...",
    duration: 3250,
  },
  {
    key: "10-digital_delirium",
    subtitle: "digital delirium and endless longing,",
    duration: 3250,
  },
  {
    key: "11-there_will_always_be_a_certain texture",
    subtitle: "there will always be a certain texture –",
    duration: 3250,
  },
  {
    key: "12-the_things_we_do_off_screen",
    subtitle: "the things we do off-screen –",
    duration: 3000,
  },
  {
    key: "13-the_unknowable_details",
    subtitle: "the unknowable details... that keep things interesting.",
    duration: 4500,
  },
  {
    key: "14-tempting_fate_as_we",
    subtitle: "Tempting fate as we might be to suggest it –",
    duration: 3250,
  },
  {
    key: "15-is_there_chance_you_might_miss",
    subtitle: "is there any chance you might miss some of this when it’s gone?",
    duration: 4250,
  },
  null,
  null,
  null,
  null,
  {
    key: "Martine_Rose_Arabic_1",
    subtitle: " ",
    duration: 1000,
  },
  null,
  null,
  null,
  {
    key: "Martine_Rose_Jap_1",
    subtitle: " ",
    duration: 1000,
  },
  null,
  null,
  null,
  {
    key: "Martine_Rose_English_1",
    subtitle: " ",
    duration: 1000,
  },
  null,
  null,
  null,
  {
    key: "Martine_Rose_OH_1",
    subtitle: " ",
    duration: 1000,
  },
];

export default audioData;
