type DisplayMode = "loading" | "intro" | "preshow" | "live" | "legacy";

export default class MRIcons {
  private el: HTMLButtonElement;
  private mrReadyEl: SVGElement;

  constructor(el: HTMLButtonElement) {
    this.el = el.querySelector("._js_mr-icons");
    this.mrReadyEl = this.el.querySelector("._js_mr-icons__mr-ready");
    this.displayMode = "loading";
  }

  public set displayMode(value: DisplayMode) {
    switch (value) {
      case "loading":
        this.el.classList.remove("mr-icons--hide-mobile");
        this.mrReadyEl.dataset.mode = "off";
        break;
      case "intro":
        this.mrReadyEl.dataset.mode = "flash";
        break;
      case "preshow":
        this.mrReadyEl.dataset.mode = "flash";
        break;
      case "live":
        this.mrReadyEl.dataset.mode = "on";
        break;
      case "legacy":
        this.mrReadyEl.dataset.mode = "off";
        break;
    }
  }

  public kill(): void {
    requestAnimationFrame(() => {
      this.el.style.display = "none";
    });
  }
}
