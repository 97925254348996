import VideoScreen from "./VideoScreen";
import { Mesh } from "three";
import { VideoData } from "../videoData";

import { VIDEO_NEARLY_ENDED_THRESHOLD } from "../../CONSTANTS";
import { ScreenEvents } from "../util/Events";

export default class RoomScreen extends VideoScreen {
  protected autoPlayOnResume = true;
  protected shouldLoop = false;
  protected showOnActive = false;
  protected _isMuted = false;
  private getTimeInterval: any;
  public data: VideoData;
  private waitingTimeout: any;

  constructor(screenMesh: Mesh, data: VideoData) {
    super(screenMesh, data);

    this.onVideoEnded = this.onVideoEnded.bind(this);
    this.onVideoPlaying = this.onVideoPlaying.bind(this);
    this.onVideoPaused = this.onVideoPaused.bind(this);
    this.pollCurrentTime = this.pollCurrentTime.bind(this);
    this.onVideoNearlyEnded = this.onVideoNearlyEnded.bind(this);
  }

  private pollCurrentTime() {
    if (
      this.video.duration - this.video.currentTime <
      VIDEO_NEARLY_ENDED_THRESHOLD / 1000
    ) {
      this.onVideoNearlyEnded();
    }
  }

  protected tryToPlay(): void {
    super.tryToPlay();
    this.waitingTimeout = setTimeout(this.onVideoWaiting, 999);
  }

  protected onVideoPlaying(): void {
    super.onVideoPlaying();
    clearInterval(this.getTimeInterval);
    clearTimeout(this.waitingTimeout);
    console.log("playing...");
    this.getTimeInterval = setInterval(this.pollCurrentTime, 111);
    const playingEvent = new CustomEvent(ScreenEvents.Playing);
    window.dispatchEvent(playingEvent);
  }

  protected onVideoPaused(): void {
    super.onVideoPaused();
    clearInterval(this.getTimeInterval);
  }

  protected onVideoWaiting(): void {
    console.log("buffering...");
    super.onVideoWaiting();
    clearTimeout(this.waitingTimeout);
    const bufferingEvent = new CustomEvent(ScreenEvents.Buffering);
    window.dispatchEvent(bufferingEvent);
  }

  protected onVideoNearlyEnded(): void {
    clearInterval(this.getTimeInterval);
    this.autoPlayOnResume = false;

    const videoscreenEndingEvent = new CustomEvent(ScreenEvents.Ending);
    window.dispatchEvent(videoscreenEndingEvent);
  }

  protected onVideoEnded(): void {
    clearInterval(this.getTimeInterval);
    super.onVideoEnded();
  }

  public deactivate(): void {
    clearInterval(this.getTimeInterval);
    clearTimeout(this.waitingTimeout);
    super.deactivate();
    super.hide();
  }
}
