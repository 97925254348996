export default class ScrollHint {
  private el: HTMLElement;
  private _userControlsEnabled = false;
  private _cameraPresetIndex = 0;
  private _isEnabled = false;

  constructor(el: HTMLElement) {
    this.el = el;
  }

  public set isEnabled(value: boolean) {
    this._isEnabled = value;
    this.checkIsActive();
  }

  public set userControlsEnabled(value: boolean) {
    this._userControlsEnabled = value;
    this.checkIsActive();
  }

  public set cameraPresetIndex(value: number) {
    this._cameraPresetIndex = value;
    this.checkIsActive();
  }

  private checkIsActive() {
    if (
      this._userControlsEnabled &&
      this._cameraPresetIndex === 0 &&
      this._isEnabled
    ) {
      this.el.classList.add("scroll-hint--is-active");
    } else {
      this.el.classList.remove("scroll-hint--is-active");
    }
  }

  public kill(): void {
    this.el.style.display = "none";
  }
}
