import { debounce as _debounce } from "lodash";
import { WebGLRenderer } from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { VignetteShader } from "three/examples/jsm/shaders/VignetteShader.js";

import MainScene from "./scene/MainScene";
import { AppEvents, IStepEvent } from "./util/Events";
import ticker from "./util/Ticker";
import { Step } from "../client";

export default class WebGLView {
  private mainScene?: MainScene;

  public renderer: WebGLRenderer;
  private composer: EffectComposer;
  private renderPass: RenderPass;
  private effectFilm: FilmPass;
  private effectVignette: ShaderPass;

  get context(): WebGLRenderingContext {
    return this.renderer.getContext();
  }

  constructor(canvas: HTMLCanvasElement) {
    let context;

    if (typeof WebGL2RenderingContext !== "undefined") {
      context = canvas.getContext("webgl2", { antialias: false });
    } else {
      context = canvas.getContext("webgl", { antialias: false });
    }

    this.renderer = new WebGLRenderer({
      canvas,
      context,
      antialias: false,
    });

    canvas.addEventListener(
      "webglcontextlost",
      () => {
        alert("webglcontextlost");
        const error = this.context.getError();
        if (
          error != this.context.NO_ERROR &&
          error != this.context.CONTEXT_LOST_WEBGL
        ) {
          alert("fail");
        }
      },
      false,
    );

    this.resizeRenderer();

    this.mainScene = new MainScene();

    this.composer = new EffectComposer(this.renderer);

    this.renderPass = new RenderPass(this.mainScene, this.mainScene.camera);
    this.effectFilm = new FilmPass(0.33, 0.33, 648, false);
    this.effectVignette = new ShaderPass(VignetteShader);
    this.effectVignette.uniforms["darkness"].value = 1.4;
    this.effectVignette.uniforms["offset"].value = 0.8;

    this.composer.addPass(this.renderPass);
    // this.composer.addPass(this.effectVignette);
    // this.composer.addPass(this.effectFilm);

    this.animate = this.animate.bind(this);
    this.update = this.update.bind(this);
    this.render = this.render.bind(this);
    this.onStepUpdated = this.onStepUpdated.bind(this);
    this.onResize = _debounce(this.onResize.bind(this), 333);

    window.addEventListener(AppEvents.Step, this.onStepUpdated);
  }

  private resizeRenderer() {
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  private onResize() {
    this.resizeRenderer();
    this.mainScene.onResize();
  }

  private init(): void {
    this.mainScene.init();
    window.addEventListener("resize", this.onResize);

    // this.update();
    // this.render();
    ticker.addCallback(this.animate);
  }

  private onStepUpdated({ detail }: IStepEvent) {
    if (detail === Step.Loading) {
      this.init();
    }
    // if (detail === Step.Enter) {
    //   ticker.addCallback(this.animate);
    // }
  }

  private animate(delta: number) {
    this.update(delta);
    this.render();
  }

  private update(delta = 1) {
    this.mainScene.update(delta);
  }

  private render() {
    this.composer.render();
  }
}
