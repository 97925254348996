export default class RoomNumber {
  private el: HTMLElement;
  private numberEl: HTMLElement;

  constructor(el: HTMLElement) {
    this.el = el;
    this.numberEl = document.querySelector("._js_room-number__number");
  }

  public set number(value: number | null) {
    requestAnimationFrame(() => {
      if (value === null) {
        this.el.style.display = "none";
        this.numberEl.innerHTML = "";
      } else {
        this.el.style.display = "";
        this.numberEl.innerHTML = `${value}`;
      }
    });
  }

  public kill(): void {
    this.el.style.display = "none";
  }
}
