import { gsap } from "gsap/all";
import ViewfinderPresetIndicator from "./ViewfinderPresetIndicator";

export default class Viewfinder {
  private el: HTMLElement;
  private presetsEl: SVGGElement;
  private presetIndicators: ViewfinderPresetIndicator[] = [];
  private zeroPresetIndicator: ViewfinderPresetIndicator;
  private _isActive = false;
  private _isVisible = false;
  private currentPreset: number | null = 0;

  constructor(el: HTMLElement) {
    this.el = el;
    this.presetsEl = this.el.querySelector("._js_viewfinder__presets");

    [...this.presetsEl.children].forEach((child: SVGRectElement) => {
      this.presetIndicators.push(new ViewfinderPresetIndicator(child));
    });
    this.zeroPresetIndicator = new ViewfinderPresetIndicator(
      this.el.querySelector("._js_viewfinder__zero-preset"),
    );
  }

  private set isVisible(value: boolean) {
    if (this._isVisible === value) return;
    this._isVisible = value;

    gsap.killTweensOf(this.el);
    if (value === true) {
      this.el.style.display = "block";
      gsap.fromTo(
        this.el,
        { scaleX: 0 },
        { scaleX: 1, duration: 0.3, ease: "power3.out" },
      );
      gsap.fromTo(
        this.el,
        { scaleY: 0.1 },
        { scaleY: 1, duration: 0.15, delay: 0.2, ease: "power3.out" },
      );
    } else {
      gsap.fromTo(
        this.el,
        { scaleX: 1 },
        { scaleX: 0, duration: 0.3, delay: 0.1, ease: "power3.out" },
      );
      gsap.fromTo(
        this.el,
        { scaleY: 1 },
        {
          scaleY: 0.1,
          duration: 0.15,
          ease: "power3.out",
          onComplete: () => (this.el.style.display = "none"),
        },
      );
    }
  }

  public set isActive(value: boolean) {
    if (this._isActive === value) return;
    this._isActive = value;

    this.checkVisibility();
  }

  public set cameraPresetIndex(value: number | null) {
    this.currentPreset = value;
    this.checkVisibility();
    requestAnimationFrame(() => {
      this.presetIndicators.forEach((child: ViewfinderPresetIndicator) => {
        child.isVisible = false;
      });
      this.zeroPresetIndicator.isVisible = false;
      if (value === null) {
        this.presetsEl.style.visibility = "hidden";
      } else if (value === 0) {
        this.zeroPresetIndicator.isVisible = true;
      } else {
        this.presetsEl.style.visibility = "visible";
        this.presetIndicators[
          (value - 1) % this.presetsEl.children.length
        ].isVisible = true;
      }
    });
  }

  private checkVisibility() {
    if (this._isActive && this.currentPreset !== 0) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }
  }

  public kill(): void {
    requestAnimationFrame(() => {
      this.el.style.display = "none";
    });
  }
}
