import { app } from "../../../client";
import IntroVideo from "../components/IntroVideo";
import LoadingBar from "../components/LoadingBar";
import StartButton from "../components/StartButton";
import Suggestions from "../components/Suggestions";
import { UIEvents, TimelineEvents, ICountdownEvent } from "../../util/Events";

export default class IntroView {
  private el: HTMLElement;
  private start: StartButton;
  private video: IntroVideo;
  private loading: LoadingBar;
  private suggestions: Suggestions;
  private isReady = false;

  constructor() {
    this.el = document.querySelector("._js_intro-view");

    this.start = new StartButton(document.querySelector("._js_start"));
    this.video = new IntroVideo(document.querySelector("._js_intro-video"));
    this.loading = new LoadingBar(document.querySelector("._js_loading"));
    this.suggestions = new Suggestions(
      document.querySelector("._js_suggestions"),
    );
    this.loading.isVisible = true;

    this.onCountdownUpdated = this.onCountdownUpdated.bind(this);
    this.onUserStart = this.onUserStart.bind(this);
    window.addEventListener(
      TimelineEvents.CountdownUpdated,
      this.onCountdownUpdated,
    );
    window.addEventListener(UIEvents.Start, this.onUserStart);
    document.body.style.cursor = "wait";
  }

  public onExperienceLoaded(): void {
    if (this.loading) {
      this.loading.kill();
    }
    if (this.start) {
      this.start.displayMode = "initializing";
    }
  }

  public onExperienceReady(): void {
    document.body.style.cursor = "";
    this.isReady = true;

    this.suggestions.init();

    if (this.start) {
      this.start.displayMode = app.timelineController.countdown.isComplete
        ? "live"
        : "preshow";
    }
  }

  private onCountdownUpdated({ detail }: ICountdownEvent) {
    if (this.isReady) {
      this.start.displayMode = detail.isComplete ? "live" : "preshow";
    }
  }

  private onUserStart() {
    this.start.kill();
    this.video.kill();
  }

  public kill(): void {
    requestAnimationFrame(() => {
      this.el.style.display = "none";
    });
    window.removeEventListener(
      TimelineEvents.CountdownUpdated,
      this.onCountdownUpdated,
    );
  }
}
