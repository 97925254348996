import audioOne from "../audio/rooms/MR-residual-Floor-1-1.mp3";
import audioTwo from "../audio/rooms/MR-residual-Floor-1-2.mp3";
import audioThree from "../audio/rooms/MR-residual-Floor-1-3.mp3";
import audioFour from "../audio/rooms/MR-residual-Floor-1-4.mp3";
import audioFive from "../audio/rooms/MR-residual-Floor-1-5.mp3";
import audioSix from "../audio/rooms/MR-residual-Floor-1-6.mp3";
import audioSeven from "../audio/rooms/MR-residual-Floor-2-1.mp3";
import audioEight from "../audio/rooms/MR-residual-Floor-2-2.mp3";
import audioNine from "../audio/rooms/MR-residual-Floor-2-3.mp3";
import audioTen from "../audio/rooms/MR-residual-Floor-2-4.mp3";
import audioEleven from "../audio/rooms/MR-residual-Floor-2-5.mp3";
import audioTwelve from "../audio/rooms/MR-residual-Floor-2-6.mp3";
import audioThirteen from "../audio/rooms/MR-residual-Floor-3-1.mp3";
import audioFourteen from "../audio/rooms/MR-residual-Floor-3-2.mp3";
import audioFifteen from "../audio/rooms/MR-residual-Floor-3-3.mp3";
import audioSixteen from "../audio/rooms/MR-residual-Floor-3-4.mp3";
import audioSeventeen from "../audio/rooms/MR-residual-Floor-3-5.mp3";
import audioEighteen from "../audio/rooms/MR-residual-Floor-3-6.mp3";
import audioNineteen from "../audio/rooms/MR-residual-Floor-4-1.mp3";
import audioTwenty from "../audio/rooms/MR-residual-Floor-4-2.mp3";
import audioTwentyOne from "../audio/rooms/MR-residual-Floor-4-3.mp3";
import audioTwentyTwo from "../audio/rooms/MR-residual-Floor-4-4.mp3";
import audioTwentyThree from "../audio/rooms/MR-residual-Floor-4-5.mp3";
import audioTwentyFour from "../audio/rooms/MR-residual-Floor-4-6.mp3";

export type VideoOrientation = "portrait" | "landscape";

export type VideoMetadata = {
  model: string;
  place: string;
  look: string;
  roomNumber: number;
};

export type BaseVideoData = {
  playbackId?: string;
  srcUrl?: string;
};

export type VideoData = {
  playbackId?: string;
  srcUrl?: string;
  orientation: VideoOrientation;
  ambientAudioSrc?: string;
  metadata: VideoMetadata;
};

export const cctvVideoData: BaseVideoData = {
  playbackId: "XN7GefESndqCMT8nyar009eAL86R6FyYE02H56wr3d5u4",
};

// you can add a playbackId to a MUX playback ID OR a srcUrl to a MP4 video.
// this is in reverse order (ie final to first, because that's the order of the screens in the model)
const videoData: VideoData[] = [
  {
    playbackId: "Up004ywtgdZ00UATQArs01CYnTfNRNy9ndBgezEtPSM00HE",
    ambientAudioSrc: audioTwentyFour,
    orientation: "portrait",
    metadata: {
      model: "Curtly",
      place: "London (England)",
      look: "TBC",
      roomNumber: 24,
    },
  },
  {
    playbackId: "tOai202LOEwGO3aV9Q61tcbVIelUyveW3zUGMSV4MwmI",
    ambientAudioSrc: audioTwentyThree,
    orientation: "portrait",
    metadata: {
      model: "Khalil",
      place: "Algiers (Algeria)",
      look: "TBC",
      roomNumber: 23,
    },
  },
  {
    playbackId: "c4Kh3cYk8CSd0233Z1naHG01HyE901CF58uz00mdrrIIKRQ",
    ambientAudioSrc: audioTwentyTwo,
    orientation: "portrait",
    metadata: {
      model: "Drake",
      place: "Toronto (Canada)",
      look: "TBC",
      roomNumber: 22,
    },
  },
  {
    playbackId: "WR1wmulYXXJsjXsZN2Ye4Dm00qtChiL02IJls9j01iRnmQ",
    ambientAudioSrc: audioTwentyOne,
    orientation: "portrait",
    metadata: {
      model: "Violet & YLOW",
      place: "Tokyo (Japan)",
      look: "TBC",
      roomNumber: 21,
    },
  },
  {
    playbackId: "aJtuw88MeooYukQRD9j12i00AQL6kPKo7Ie8NktBOB9A",
    ambientAudioSrc: audioTwenty,
    orientation: "portrait",
    metadata: {
      model: "Big Youth",
      place: "Kingston (Jamaica)",
      look: "TBC",
      roomNumber: 20,
    },
  },
  {
    playbackId: "v8Ze6CKpKBsPLJ2c3sxKZhgYOdO46QzSlCVjq6sPwb00",
    ambientAudioSrc: audioNineteen,
    orientation: "portrait",
    metadata: {
      model: "VINCH & Nalani-Paris",
      place: "London (England)",
      look: "TBC",
      roomNumber: 19,
    },
  },
  {
    playbackId: "D02Pv6MVYD7b9qgfpKWhHPfomOGMSXnVcho22t9BHWwU",
    ambientAudioSrc: audioEighteen,
    orientation: "portrait",
    metadata: {
      model: "Nadia",
      place: "Paris (France)",
      look: "TBC",
      roomNumber: 18,
    },
  },
  {
    playbackId: "fvGr00dkxGXauLwgxA4VhzpPopbHwU00R500G9YIIdTnwA",
    ambientAudioSrc: audioSeventeen,
    orientation: "portrait",
    metadata: {
      model: "Justin",
      place: "London (England)",
      look: "TBC",
      roomNumber: 17,
    },
  },
  {
    playbackId: "028jFsw02lH00HMIhW02zyRsrLBRbOSrMKkTEZYwdq6IkzM",
    orientation: "portrait",
    ambientAudioSrc: audioSixteen,
    metadata: {
      model: "Christopher",
      place: "Toronto (Canada)",
      look: "TBC",
      roomNumber: 16,
    },
  },
  {
    playbackId: "zZoqrfctMb02LzmTQToOWykehEFMNQtUOcWj3hLNu00lI",
    ambientAudioSrc: audioFifteen,
    orientation: "portrait",
    metadata: {
      model: "Urara & Keiko",
      place: "Chiba (Japan)",
      look: "TBC",
      roomNumber: 15,
    },
  },
  {
    playbackId: "pMpD70000d71mu6FKOg564NEnPdMupbhRIo7zmGjcAXJs",
    ambientAudioSrc: audioFourteen,
    orientation: "portrait",
    metadata: {
      model: "Mayra & Alfredo",
      place: "Mexico City (Mexico)",
      look: "TBC",
      roomNumber: 14,
    },
  },
  {
    playbackId: "Xc9mf00CcJXawHQ1ijGtlAK8kN2wxiIgm0102oUd3sPs7M",
    orientation: "portrait",
    ambientAudioSrc: audioThirteen,
    metadata: {
      model: "Steven",
      place: "Los Angeles (USA)",
      look: "TBC",
      roomNumber: 13,
    },
  },
  {
    playbackId: "nvz2dQYzKoYCxC4rj5wJTTTS6pgdlYaibl4hiUG7Eoo",
    ambientAudioSrc: audioTwelve,
    orientation: "portrait",
    metadata: {
      model: "Max & Mariya",
      place: "Moscow (Russia)",
      look: "TBC",
      roomNumber: 12,
    },
  },
  {
    playbackId: "Sqz9lM6ife00vdDy028900OCtHx4kB9xb95ZG01H00iCQ3GM",
    ambientAudioSrc: audioEleven,
    orientation: "portrait",
    metadata: {
      model: "Victor",
      place: "Los Angeles (USA)",
      look: "TBC",
      roomNumber: 11,
    },
  },
  {
    playbackId: "4qds01onGsKJrToJRIRHrsxfO8DvX700imnKVMu00qxiQA",
    ambientAudioSrc: audioTen,
    orientation: "portrait",
    metadata: {
      model: "Talvi",
      place: "Toronto (Canada)",
      look: "TBC",
      roomNumber: 10,
    },
  },
  {
    playbackId: "P9gwExqck7eLzeC1P02KA4wQLERliEbQfl2QVEyrRmQo",
    ambientAudioSrc: audioNine,
    orientation: "portrait",
    metadata: {
      model: "Mohamed, Khaled & Alyssa",
      place: "Ramallah (Palestine)",
      look: "TBC",
      roomNumber: 9,
    },
  },
  {
    playbackId: "qc1ovcZb85Sh5oZJaX2B6Spzy4tKZgRadDSdA7kCHbU",
    ambientAudioSrc: audioEight,
    orientation: "portrait",
    metadata: {
      model: "Trudy",
      place: "Washington (USA)",
      look: "TBC",
      roomNumber: 8,
    },
  },
  {
    playbackId: "lWXWea01UjXhC600IeWtjI4Y7XSVJuFqxQDflJrKFam6A",
    ambientAudioSrc: audioSeven,
    orientation: "portrait",
    metadata: {
      model: "Cola Boyy",
      place: "Oxnard (USA)",
      look: "TBC",
      roomNumber: 7,
    },
  },
  {
    playbackId: "i01zIzjLiCyhUjru4DJ9C6X01MpmT8O00qtqEaEZiE02fTI",
    ambientAudioSrc: audioSix,
    orientation: "portrait",
    metadata: {
      model: "Darren, Reuben & Valentine",
      place: "London (England)",
      look: "TBC",
      roomNumber: 6,
    },
  },
  {
    playbackId: "VuTNA8IIbLPlblDa00NGlK2WG3tVMdoQCAcJB01aslNms",
    ambientAudioSrc: audioFive,
    orientation: "portrait",
    metadata: {
      model: "Elsa",
      place: "Nairobi (Kenya)",
      look: "TBC",
      roomNumber: 5,
    },
  },
  {
    playbackId: "d9o28JC3EAFOVvcAePqulDvTPVdmI6hXmXQ01kqQQahc",
    ambientAudioSrc: audioFour,
    orientation: "portrait",
    metadata: {
      model: "Carla & Leonel",
      place: "Mexico City (Mexico)",
      look: "TBC",
      roomNumber: 4,
    },
  },
  {
    playbackId: "8y902m1F1CFKNhQlZlJP1edNNeVWbwamEuJnhrvEQkzY",
    ambientAudioSrc: audioThree,
    orientation: "portrait",
    metadata: {
      model: "OH",
      place: "Seoul (Korea)",
      look: "TBC",
      roomNumber: 3,
    },
  },
  {
    playbackId: "P1x2dFLeH9gDAwNHUafmSeIJTbRrvjqNSvTbwJRZbpE",
    ambientAudioSrc: audioTwo,
    orientation: "portrait",
    metadata: {
      model: "Mark",
      place: "Wallhausen (Germany)",
      look: "TBC",
      roomNumber: 2,
    },
  },
  {
    playbackId: "e6ibECVfy95VbeCeoul1vkmnu6px01tUochy2CVbJ1YY",
    ambientAudioSrc: audioOne,
    orientation: "portrait",
    metadata: {
      model: "John",
      place: "Los Angeles (USA)",
      look: "TBC",
      roomNumber: 1,
    },
  },
];

export default videoData;
