import {
  VideoTexture,
  RGBFormat,
  LinearFilter,
  Mesh,
  MeshBasicMaterial,
  DoubleSide,
  Texture,
} from "three";
import { BaseVideoData } from "../videoData";
import { MartineRosePanopticonApp } from "../../client";
import Hls from "hls.js";
import iOSVersion from "ios-version";
import { UIEvents, ScreenEvents } from "../util/Events";

const isiOS = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export default class VideoScreen {
  protected autoPlayOnResume = false;
  protected shouldLoop = true;
  protected showOnActive = true;
  protected _isMuted = true;
  public data: BaseVideoData;
  public screenMesh: Mesh;
  public isActive = false;
  private videoTexture?: Texture;
  public video?: HTMLVideoElement;
  private hls?: Hls;

  static createNewVideo(shouldLoop = false, isMuted = false): HTMLVideoElement {
    const video = document.createElement("video");
    video.loop = shouldLoop;
    video.muted = isMuted;
    video.crossOrigin = "anonymous";
    video.setAttribute("playsinline", "true");
    video.setAttribute("controls", "true");
    video.style.position = "fixed";
    video.style.top = "0px";
    video.style.left = "0px";
    video.style.width = "50px";
    video.style.height = "auto";

    return video;
  }

  static createNewTexture(video: HTMLVideoElement): VideoTexture {
    const videoTexture = new VideoTexture(video);
    videoTexture.minFilter = LinearFilter;
    videoTexture.magFilter = LinearFilter;
    videoTexture.format = RGBFormat;
    videoTexture.flipY = false;
    return videoTexture;
  }

  constructor(screenMesh: Mesh, data: BaseVideoData) {
    this.data = data;
    this.screenMesh = screenMesh;
    this.screenMesh.visible = false;

    this.screenMesh.material = new MeshBasicMaterial({
      color: 0xffffff,
      side: DoubleSide,
    });

    this.tryToPlay = this.tryToPlay.bind(this);
    this.onCannotAutoplay = this.onCannotAutoplay.bind(this);
    this.onUserClickPlay = this.onUserClickPlay.bind(this);
    this.onVideoPlaying = this.onVideoPlaying.bind(this);
    this.onVideoPaused = this.onVideoPaused.bind(this);
    this.onVideoEnded = this.onVideoEnded.bind(this);
    this.onVideoWaiting = this.onVideoWaiting.bind(this);
    this.init = this.init.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  protected get videoSrc(): string {
    const iOS = iOSVersion(navigator.userAgent);
    if (!!iOS && iOS.major === 14 && (iOS.minor === 1 || iOS.minor === 3))
      return `https://stream.mux.com/${this.data.playbackId}/medium.mp4`; // ios 14.1 and 14.3 have a bug with HLS video used as webgl textures
    if (this.data.playbackId)
      return `https://stream.mux.com/${this.data.playbackId}.m3u8`;
    if (this.data.srcUrl) return this.data.srcUrl;
    return null;
  }

  public set isMuted(value: boolean) {
    if (this.video) this.video.muted = value;
    this._isMuted = value;
  }

  public get isMuted(): boolean {
    return this._isMuted;
  }

  public init(): void {
    this.video = VideoScreen.createNewVideo(this.shouldLoop, this.isMuted);
    if (this.videoTexture) this.videoTexture.dispose();
    this.videoTexture = VideoScreen.createNewTexture(this.video);

    if (
      MartineRosePanopticonApp.videoSupport === "hls" &&
      this.videoSrc.indexOf(".mp4") === -1
    ) {
      this.hls = new Hls();
      this.hls.loadSource(this.videoSrc);
      this.hls.attachMedia(this.video);
    } else {
      this.video.src = this.videoSrc;
    }
  }

  protected tryToPlay(): void {
    const playPromise = this.video.play();
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          const autoplaySucceededEvent = new CustomEvent(
            ScreenEvents.AutoplaySucceeded,
          );
          window.dispatchEvent(autoplaySucceededEvent);
        })
        .catch(this.onCannotAutoplay);
    } else {
      const autoplaySucceededEvent = new CustomEvent(
        ScreenEvents.AutoplaySucceeded,
      );
      window.dispatchEvent(autoplaySucceededEvent);
    }
  }

  public activate(): void {
    if (this.isActive) return;
    this.isActive = true;
    if (this.showOnActive) {
      this.onVideoPlaying();
    }
    this.video.addEventListener("ended", this.onVideoEnded);
    this.video.addEventListener("playing", this.onVideoPlaying);
    this.video.addEventListener("pause", this.onVideoPaused);
    this.tryToPlay();
    window.addEventListener("focus", this.onFocus);
    window.addEventListener("blur", this.onBlur);
  }

  public deactivate(): void {
    this.isActive = false;
    window.removeEventListener(UIEvents.ResumePlayback, this.onUserClickPlay);

    this.video.removeEventListener("ended", this.onVideoEnded);
    this.video.removeEventListener("playing", this.onVideoPlaying);
    this.video.removeEventListener("pause", this.onVideoPaused);
    this.video.pause();
    if (this.hls) {
      this.hls.detachMedia();
      this.hls.destroy();
      delete this.hls;
    }
    delete this.video;

    window.removeEventListener("focus", this.onFocus);
    window.removeEventListener("blur", this.onBlur);
  }

  public hide(): void {
    this.screenMesh.visible = false;
    if (this.videoTexture) this.videoTexture.dispose();

    this.screenMesh.material.needsUpdate = true;
  }

  protected onVideoPlaying(): void {
    this.screenMesh.visible = true;
    this.screenMesh.material.map = this.videoTexture;
    this.screenMesh.material.map.needsUpdate = true;
    this.screenMesh.material.needsUpdate = true;
  }

  protected onVideoPaused(): void {}

  protected onVideoEnded(): void {}

  protected onVideoWaiting(): void {}

  protected onCannotAutoplay(): void {
    window.addEventListener(UIEvents.ResumePlayback, this.onUserClickPlay);

    const autoplayFailedEvent = new CustomEvent(ScreenEvents.AutoplayFailed);
    window.dispatchEvent(autoplayFailedEvent);
  }

  private onUserClickPlay() {
    window.removeEventListener(UIEvents.ResumePlayback, this.onUserClickPlay);

    this.tryToPlay();
  }

  private onFocus() {
    if (this.autoPlayOnResume) {
      this.tryToPlay();
    }
  }

  private onBlur() {
    if (this.autoPlayOnResume) this.video.pause();
  }
}
