import io from "socket.io-client";

import {
  TimelineEvents,
  UIEvents,
  IUpdateRoomDataEvent,
  IUpdateSubtitleEvent,
  ICountdownEvent,
  IUpdateCameraPresetEvent,
  IStepEvent,
  AppEvents,
  ScreenEvents,
  IUserControlsEvent,
} from "../../util/Events";
import { app, Step } from "../../../client";

import { USER_COUNT_URI } from "../../../CONSTANTS";

import CameraPreset from "../components/CameraPreset";
import Clock from "../components/Clock";
// import RoomData from "../components/RoomData";
import Subtitles from "../components/Subtitles";
import ViewerCount from "../components/ViewerCount";
import Viewfinder from "../components/Viewfinder";
import MRIcons from "../components/MRIcons";
import MuteButton from "../components/MuteButton";
import FullscreenButton from "../components/FullscreenButton";
import ShareButton from "../components/ShareButton";
import ScrollHint from "../components/ScrollHint";
import RoomNumber from "../components/RoomNumber";
import LoadingBar from "../components/LoadingBar";

export default class HUDView {
  private clock: Clock;
  private mrIcons: MRIcons;
  private cameraPreset?: CameraPreset;
  private roomNumber: RoomNumber;
  private subtitles: Subtitles;
  private viewfinder: Viewfinder;
  private viewerCount: ViewerCount;
  private muteButton: MuteButton;
  private shareButton: ShareButton;
  private fullscreenButton: FullscreenButton;
  private loadingBar: LoadingBar;
  private buttonsContainer: HTMLElement;
  private iconsContainer: HTMLElement;
  private scrollHint: ScrollHint;
  private socket: any;

  constructor() {
    this.onCountdownUpdated = this.onCountdownUpdated.bind(this);
    this.onCameraPresetUpdated = this.onCameraPresetUpdated.bind(this);
    this.onRoomDataUpdated = this.onRoomDataUpdated.bind(this);
    this.onSubtitleUpdated = this.onSubtitleUpdated.bind(this);
    this.onStepUpdated = this.onStepUpdated.bind(this);
    this.onCameraMoving = this.onCameraMoving.bind(this);
    this.onCameraStopMoving = this.onCameraStopMoving.bind(this);
    this.updateViewerCount = this.updateViewerCount.bind(this);
    this.onUserControlsChanged = this.onUserControlsChanged.bind(this);
    this.onAutoplayFailed = this.onAutoplayFailed.bind(this);
    this.onBuffering = this.onBuffering.bind(this);
    this.onPlaying = this.onPlaying.bind(this);

    this.socket = io(USER_COUNT_URI, { transports: ["websocket"] });
    this.socket.on("counter", this.updateViewerCount);

    this.buttonsContainer = document.querySelector(
      "._js_hud-buttons",
    ) as HTMLElement;
    this.iconsContainer = document.querySelector(
      "._js_hud-icons",
    ) as HTMLElement;

    this.loadingBar = new LoadingBar(document.querySelector("._js_buffering"));
    this.clock = new Clock(document.querySelector("._js_clock"));
    this.mrIcons = new MRIcons(
      document.querySelector("._js_hud-view__content"),
    );
    this.viewfinder = new Viewfinder(document.querySelector("._js_viewfinder"));
    this.cameraPreset = new CameraPreset(
      document.querySelector("._js_camera-preset"),
    );

    this.roomNumber = new RoomNumber(
      document.querySelector("._js_room-number"),
    );
    this.subtitles = new Subtitles(document.querySelector("._js_subtitles"));
    this.viewerCount = new ViewerCount(
      document.querySelector("._js_viewer-count"),
    );
    this.muteButton = new MuteButton(
      document.querySelector("._js_mute-button"),
    );
    this.fullscreenButton = new FullscreenButton(
      document.querySelector("._js_fullscreen-button"),
    );
    this.shareButton = new ShareButton(document.querySelector("._js_share"));
    this.scrollHint = new ScrollHint(
      document.querySelector("._js_scroll-hint"),
    );

    if (app.timelineController.mode === "current") {
      window.addEventListener(
        TimelineEvents.CountdownUpdated,
        this.onCountdownUpdated,
      );
      if (app.timelineController.countdown.isComplete) {
        this.clock.isLive = true;
      } else {
        this.clock.countdown = app.timelineController.countdown;
      }
    }
    window.addEventListener(
      UIEvents.UpdateCameraPreset,
      this.onCameraPresetUpdated,
    );
    window.addEventListener(UIEvents.UpdateRoomData, this.onRoomDataUpdated);
    window.addEventListener(UIEvents.UpdateSubtitle, this.onSubtitleUpdated);
    window.addEventListener(UIEvents.UserControls, this.onUserControlsChanged);
    window.addEventListener(AppEvents.Step, this.onStepUpdated);
    window.addEventListener(ScreenEvents.GoTo, this.onCameraMoving);
    window.addEventListener(ScreenEvents.GoToComplete, this.onCameraStopMoving);
    window.addEventListener(ScreenEvents.Buffering, this.onBuffering);
    window.addEventListener(ScreenEvents.Playing, this.onPlaying);
  }

  private onBuffering() {
    this.loadingBar.isVisible = true;
  }

  private onPlaying() {
    this.loadingBar.isVisible = false;
  }

  private onAutoplayFailed() {
    this.viewfinder.isActive = false;
  }

  private onUserControlsChanged({ detail }: IUserControlsEvent) {
    this.scrollHint.userControlsEnabled = detail;
  }

  private updateViewerCount({ count }: { count: number }) {
    this.viewerCount.count = count;
  }

  private onCountdownUpdated({ detail }: ICountdownEvent) {
    if (detail.isComplete) {
      this.clock.isLive = true;
      this.mrIcons.displayMode = "live";
    } else {
      this.clock.countdown = detail;
    }
  }

  private onCameraPresetUpdated({ detail }: IUpdateCameraPresetEvent) {
    this.cameraPreset.index = detail;
    this.viewfinder.cameraPresetIndex = detail;
    this.scrollHint.cameraPresetIndex = detail;
  }

  private onRoomDataUpdated({ detail }: IUpdateRoomDataEvent) {
    this.roomNumber.number = detail ? detail.data.roomNumber : null;
  }

  private onSubtitleUpdated({ detail }: IUpdateSubtitleEvent) {
    this.subtitles.subtitle = detail || null;
  }

  private onCameraMoving() {
    this.viewfinder.isActive = true;
  }

  private onCameraStopMoving() {
    this.viewfinder.isActive = false;
  }

  private onStepUpdated({ detail }: IStepEvent) {
    if (detail === Step.Ready) {
      this.mrIcons.displayMode = "intro";
    }
    if (detail === Step.Enter) {
      this.buttonsContainer.style.opacity = "1";
      this.iconsContainer.style.opacity = "1";
    }
    if (detail === Step.PreShow) {
      this.mrIcons.displayMode = "preshow";
      this.viewfinder.isActive = true;
      this.scrollHint.isEnabled = true;
    }
    if (detail === Step.Show) {
      this.scrollHint.isEnabled = false;
      this.mrIcons.displayMode = "live";
    }
    if (detail === Step.PostShow) {
      this.scrollHint.isEnabled = false;
    }
    if (detail === Step.PostShow || detail === Step.Legacy) {
      this.mrIcons.displayMode = "legacy";
      this.clock.nextShow = app.timelineController.nextShowtime;
    }
    if (detail === Step.Legacy) {
      this.scrollHint.isEnabled = true;
    }
    if (detail === Step.PostCredits) {
      this.scrollHint.isEnabled = true;
      this.viewfinder.isActive = true;
    }
  }
}
