export const INNER_ANGLE = 0;
export const OUTER_ANGLE = 110;
export const OUTER_GAIN = 0.02;
export const ROLLOFF = 15;
export const REF_DIST = 15;
export const MAX_DIST = 1000;
export const DIST_MODEL = "inverse";
export const VIDEO_TRAIL_VOLUME = 1;
export const AMBIENT_VOLUME = 1;
export const INTRO_TRACK_VOLUME = 0.5;
export const SCREEN_VOLUME = 1;
export const DRONE_VOLUME = 0.15;
export const MAX_AMBIENT_AUDIO = window.innerWidth <= 1024 ? 8 : 8;

export const LO_RESOLUTION_MAX = 320 * 668; // iPad has to use lo res textures, or it'll crash
export const MED_RESOLUTION_MAX = 1024 * 768;

export const INTRO_VIDEO_SRC =
  "https://stream.mux.com/N6wXoTpWUxUuawJSiEzF3Z6OOBy31YcP00QEM3aDsIi00.m3u8";

export const SHOW_TOTAL_DURATION = 1000 * 60 * 10;
export const AVERAGE_VIDEO_DURATION = 1000 * 30;

export const VIDEO_NEARLY_ENDED_THRESHOLD = 5555;
export const USER_COUNT_URI =
  "https://martine-rose-panopticon-count.herokuapp.com/";

export const BIRD_COUNT = window.innerWidth <= 1024 ? 33 : 44;
export const BIRD_HEIGHT_VARIATION = 8;
export const BIRD_SETTINGS = {
  bounds: { x: 10, y: 10, z: 10 },
  maxVel: 0.75,
  maxSteer: 0.03,
  seperation: 2,
  viewDist: 20, // how close siblings have to be for the bird to 'see' them and react to them
};

export const SUGGESTIONS = [
  "What We Do All Day.",
  "Find a peaceful corner, somewhere you won’t be disturbed.",
  "Switch off anything that beeps, blinks, shudders or vibrates.",
  "We want your bandwidth and we want your attention to ourselves.",
  "Plug in headphones or speakers if you have them.",
  "Try to stay with us from beginning to end.",
  "Enjoy yourself.",
];

export const SUGGESTIONS_DURATION = 5000;
