import srcs from "../../audio/ui/*.mp3";
import { AudioContext as THREEAudioContext, AudioListener, Audio } from "three";
import { UIEvents, IMuteUnmuteEvent } from "../util/Events";
import { audioLoader } from "../util/Loaders";
import { CameraPresetUtterance } from "../audioData";

class UISfx {
  private sfx: Map<string, Audio> = new Map();
  private listener: AudioListener = new AudioListener();

  constructor() {
    this.onUserStart = this.onUserStart.bind(this);

    for (const key in srcs) {
      const audio = new Audio(this.listener);
      audio.setVolume(1);
      audio.setLoop(false);

      audioLoader.load(srcs[key], buffer => {
        audio.setBuffer(buffer);
      });
      this.sfx.set(key, audio);
    }

    window.addEventListener(
      UIEvents.MuteUnmute,
      ({ detail }: IMuteUnmuteEvent) => (this.isMuted = detail),
    );
    window.addEventListener(UIEvents.Start, this.onUserStart);
  }

  public set isMuted(value: boolean) {
    if (value) {
      this.listener.setMasterVolume(0);
    } else {
      this.listener.setMasterVolume(1);
    }
  }

  private onUserStart(): void {
    this.requestPermission();
  }

  private requestPermission() {
    const audioContext = THREEAudioContext.getContext();

    if (audioContext.state === "suspended") {
      audioContext.resume();
    }
  }

  public play(key: string, utterance?: CameraPresetUtterance) {
    if (this.sfx.has(key)) {
      const audio = this.sfx.get(key);
      if (utterance) {
        const updateSubtitleEvent = new CustomEvent(UIEvents.UpdateSubtitle, {
          detail: utterance.subtitle,
        });
        window.dispatchEvent(updateSubtitleEvent);
        setTimeout(() => {
          const updateSubtitleEvent = new CustomEvent(UIEvents.UpdateSubtitle, {
            detail: null,
          });
          window.dispatchEvent(updateSubtitleEvent);
        }, utterance.duration);
      }

      audio.play();
    }
  }
}

export default new UISfx();
