import moment from "moment";
import { ICountdown } from "scripts/TimelineController";

import { pad } from "../../lib/misc";

export default class Clock {
  private el: HTMLElement;
  private liveEl: HTMLSpanElement;
  private nextShowEl: HTMLSpanElement;
  private countdownEl: HTMLSpanElement;
  private countdownDaysEl: HTMLSpanElement;
  private countdownHoursEl: HTMLSpanElement;
  private countdownMinsEl: HTMLSpanElement;
  private countdownSecsEl: HTMLSpanElement;

  constructor(el: HTMLDivElement) {
    this.el = el;
    this.el.style.display = "block";
    this.liveEl = el.querySelector("._js_clock__live");
    this.nextShowEl = el.querySelector("._js_clock__next-show");
    this.countdownEl = el.querySelector("._js_clock__countdown");
    this.countdownDaysEl = el.querySelector("._js_clock__countdown-days");
    this.countdownHoursEl = el.querySelector("._js_clock__countdown-hours");
    this.countdownMinsEl = el.querySelector("._js_clock__countdown-mins");
    this.countdownSecsEl = el.querySelector("._js_clock__countdown-secs");
  }

  public set isLive(value: boolean) {
    requestAnimationFrame(() => {
      if (value === true) {
        this.liveEl.style.display = "";
        this.nextShowEl.style.display = "none";
        this.countdownEl.style.display = "none";
      }
    });
  }

  public set countdown({ days, hours, minutes, seconds }: ICountdown) {
    requestAnimationFrame(() => {
      this.countdownDaysEl.innerHTML = pad(days);
      this.countdownHoursEl.innerHTML = pad(hours);
      this.countdownMinsEl.innerHTML = pad(minutes);
      this.countdownSecsEl.innerHTML = pad(seconds);
      this.countdownEl.style.display = "";
      this.liveEl.style.display = "none";
      this.nextShowEl.style.display = "none";
    });
  }

  public set nextShow(dateTime: Date | null) {
    requestAnimationFrame(() => {
      if (dateTime === null) {
        this.nextShowEl.innerHTML = "All shows are finished";
      } else {
        this.nextShowEl.innerHTML = moment(dateTime).format(
          "HH:mm:ss DD.MM.YYYY",
        );
      }
      this.nextShowEl.style.display = "";
      this.liveEl.style.display = "none";
      this.countdownEl.style.display = "none";
    });
  }
}
