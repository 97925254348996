import { Scene, Mesh, Vector3, Object3D } from "three";
import videoData, { cctvVideoData } from "../videoData";

import VideoScreen from "./VideoScreen";
import RoomScreen from "./RoomScreen";
import MainScene from "./MainScene";
import {
  UIEvents,
  ScreenEvents,
  IQueueScreenEvent,
  AppEvents,
  IStepEvent,
  IMuteUnmuteEvent,
} from "../util/Events";
import { Step } from "../../client";
import { VIDEO_NEARLY_ENDED_THRESHOLD } from "../../CONSTANTS";

export default class RoomScreenController extends Object3D {
  private screens: RoomScreen[] = [];
  private cctv: VideoScreen;
  private previousScreenIndex: number | null = null;
  private currentScreenIndex: number | null = null;
  private queuedScreenIndex: number | null = null;
  private isMuted = false;

  constructor() {
    super();

    this.queScreen = this.queScreen.bind(this);
    this.switchToQueuedScreen = this.switchToQueuedScreen.bind(this);
    this.onSwitchComplete = this.onSwitchComplete.bind(this);
    this.onStepUpdated = this.onStepUpdated.bind(this);
    this.deactivateScreen = this.deactivateScreen.bind(this);
    this.muteUnmute = this.muteUnmute.bind(this);
    this.onUserStart = this.onUserStart.bind(this);
  }

  public get previousScreen(): RoomScreen {
    if (this.previousScreenIndex === null) return;
    return this.screens[this.previousScreenIndex];
  }

  public get currentScreen(): RoomScreen {
    if (this.currentScreenIndex === null) return;
    return this.screens[this.currentScreenIndex];
  }

  public get queuedScreen(): RoomScreen {
    if (this.queuedScreenIndex === null) return;
    return this.screens[this.queuedScreenIndex];
  }

  public init(): void {
    window.addEventListener(AppEvents.Step, this.onStepUpdated);
    window.addEventListener(UIEvents.Start, this.onUserStart);
    window.addEventListener(ScreenEvents.QueueScreen, this.queScreen);
    window.addEventListener(UIEvents.MuteUnmute, this.muteUnmute);
  }

  public setupScreens(screensParent: Scene): void {
    this.cctv = new VideoScreen(
      screensParent.getObjectByName("CCTV-placeholder") as Mesh,
      cctvVideoData,
    );
    let index = 0;
    screensParent
      .getObjectByName("Screens")
      .remove(screensParent.getObjectByName("extra"));
    screensParent.getObjectByName("Screens").traverse(child => {
      if (child.type === "Mesh") {
        this.screens.push(
          new RoomScreen(child as Mesh, videoData[index % videoData.length]),
        );
        index++;
      }
    });

    screensParent.getObjectByName("Extrascreens").traverse(child => {
      if (child.type === "Mesh") {
        child.visible = false;
      }
    });
    this.cctv.init();
  }

  private muteUnmute({ detail }: IMuteUnmuteEvent) {
    this.screens.forEach(screen => (screen.isMuted = detail));
  }

  private onUserStart() {
    this.cctv.activate();
  }

  private onStepUpdated({ detail }: IStepEvent) {
    if (detail === Step.Enter) {
      this.cctv.activate();
    }

    if (detail === Step.PostShow) {
      setTimeout(() => {
        this.deactivateScreen(this.currentScreen);
        this.cctv.init();
        this.cctv.activate();
      }, VIDEO_NEARLY_ENDED_THRESHOLD);

      const updateSubtitleEvent = new CustomEvent(UIEvents.UpdateSubtitle, {
        detail: null,
      });
      window.dispatchEvent(updateSubtitleEvent);
      const updateRoomDataEvent = new CustomEvent(UIEvents.UpdateRoomData, {
        detail: null,
      });
      window.dispatchEvent(updateRoomDataEvent);
    }
  }

  public queScreen(event: IQueueScreenEvent): void {
    this.queuedScreenIndex = event.detail.index;
    this.queuedScreen.init();

    (this.parent as MainScene).ambientAudio.addScreenAudio(this.queuedScreen);

    // switchImmidiate happens if  you join the show in the middle, ie. go straight from intro > room
    if (event.detail.switchImmidiate) {
      this.switchToQueuedScreen();
    } else {
      window.addEventListener(
        ScreenEvents.GoToHalfway,
        this.switchToQueuedScreen,
      );
    }
    window.addEventListener(ScreenEvents.GoToComplete, this.onSwitchComplete);
    const goToScreenEvent = new CustomEvent(ScreenEvents.GoTo, {
      detail: this.queuedScreenIndex,
    });
    window.dispatchEvent(goToScreenEvent);
  }

  public switchToQueuedScreen(): void {
    if (this.cctv.isActive) this.cctv.deactivate();
    this.previousScreenIndex = this.currentScreenIndex;
    this.currentScreenIndex = this.queuedScreenIndex;
    this.deactivateScreen(this.previousScreen);
    this.currentScreen.activate();

    window.removeEventListener(
      ScreenEvents.GoToHalfway,
      this.switchToQueuedScreen,
    );

    const updateSubtitleEvent = new CustomEvent(UIEvents.UpdateSubtitle, {
      detail: `${this.currentScreen.data.metadata.model}, ${this.currentScreen.data.metadata.place}`,
    });
    window.dispatchEvent(updateSubtitleEvent);
    const updateRoomDataEvent = new CustomEvent(UIEvents.UpdateRoomData, {
      detail: {
        data: this.currentScreen.data.metadata,
        index: this.currentScreenIndex,
      },
    });
    window.dispatchEvent(updateRoomDataEvent);
  }

  private onSwitchComplete(): void {
    window.removeEventListener(
      ScreenEvents.GoToComplete,
      this.onSwitchComplete,
    );
  }

  public deactivateScreen(screen: RoomScreen): void {
    if (!screen) return;
    screen.deactivate();
    const updateSubtitleEvent = new CustomEvent(UIEvents.UpdateSubtitle, {
      detail: null,
    });
    window.dispatchEvent(updateSubtitleEvent);
    (this.parent as MainScene).ambientAudio.removeScreenAudio(screen);
    (this.parent as MainScene).ambientAudio.addPositionalAudio({
      name: "screen-audio",
      src: screen.data.ambientAudioSrc,
      position: screen.screenMesh.getWorldPosition(new Vector3()),
    });
  }
}
