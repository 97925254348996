import {
  PerspectiveCamera,
  Cylindrical,
  Vector3,
  CubicBezierCurve3,
  Object3D,
} from "three";
import { gsap } from "gsap/all";
import WheelSwipe from "wheel-swipe";
import SwipeListener from "swipe-listener";
import { convertToRange } from "../lib/maths.js";
import SimplexNoise from "simplex-noise";
import { throttle as _throttle } from "lodash";
import {
  TimelineEvents,
  UIEvents,
  IGoToScreenEvent,
  ScreenEvents,
  AppEvents,
  IStepEvent,
} from "../util/Events";
import { Step } from "../../client";
import uiSfx from "../ui/UISfx";
import { cameraPresetUtterances } from "../audioData";
import MainScene from "./MainScene.js";
new WheelSwipe();

const TWO_PI = Math.PI * 2;
const MAX_RADIUS = 18;
const MIN_BOUNDS = new Vector3(-MAX_RADIUS, 0, -MAX_RADIUS);
const MAX_BOUNDS = new Vector3(MAX_RADIUS, 17, MAX_RADIUS);

const ROTATION_ORDER = "YXZ";

const CAMERA_PULL_OUT_TIME = 1.5;
const CAMERA_PULL_IN_TIME = 3;

interface CameraPreset {
  position: Cylindrical;
  target: Cylindrical;
}

const noiseX = new SimplexNoise("x");
const noiseY = new SimplexNoise("y");
const noiseZ = new SimplexNoise("z");

export default class CameraController {
  public camera: PerspectiveCamera;
  public cameraCylndrical: Cylindrical; // the current camera position as a cylindrical

  private cameraSceneTarget: Vector3; // where the camera needs to look towards in the scene
  private cameraSceneTargetCylndrical: Cylindrical; // the current camera 'lookAt' as a cylindrival
  private cameraTarget: Vector3; // the actual camera target. In the direction of the camera target but closer. Used to offset camera target on mouse move
  private cameraTargetDirection: Vector3;
  private cameraTargetCross: Vector3;
  private cameraPositionRotationObject: Object3D;
  private introPath?: CubicBezierCurve3;
  private droneAudioTimeout?: any;

  private userControlsEnabled = false;
  private scrollStripe: ScrollSwipe;
  private allowExploreOnEntered = true;
  private playUtterances = true;

  private time = 0;

  private xOffsetTarget = 0;
  private yOffsetTarget = 0;

  private xOffsetCurrent = 0;
  private yOffsetCurrent = 0;

  private _positionOffsetWeight = 1;

  private windowWidth: number;
  private windowHeight: number;

  private orientationCalibrationValues: number[] = [];

  private _cameraRoomIndex: number | null = null;
  private _cameraPresetIndex: number | null = null;
  private cameraPresets: CameraPreset[] = [];
  private cameraRoomPresets: CameraPreset[] = [];
  private enableUserControlsTimeout: any;

  static clampCylindricalTheta(cylindrical: Cylindrical): Cylindrical {
    if (cylindrical.theta < 0) {
      cylindrical.theta += TWO_PI; // reset to positive for next transition
    } else if (cylindrical.theta > TWO_PI) {
      cylindrical.theta -= TWO_PI; // reset to < 2PI for next transition
    }
    return cylindrical;
  }

  static findShortestRouteRoundCylindrical(
    from: Cylindrical,
    to: Cylindrical,
  ): Cylindrical {
    let thetaDifference = to.theta - from.theta;

    // console.log(`
    //   current: ${from.theta}\n
    //   next: ${to.theta}\n
    //   difference: ${thetaDifference}
    // `);
    // Find the shortest route to the theta (ie. not all around the cylinder)
    if (Math.abs(thetaDifference) > Math.PI) {
      if (to.theta > from.theta) {
        to.theta -= TWO_PI;
      } else if (to.theta < from.theta) {
        to.theta += TWO_PI;
      }
      thetaDifference = to.theta - from.theta;
      //   console.log(`
      //   NEW\n
      //   current: ${from.theta}\n
      //   next: ${to.theta}\n
      //   difference: ${thetaDifference}
      // `);
    }

    return to;
  }

  static calculateCameraPreset(camera: PerspectiveCamera): CameraPreset {
    const position = camera.getWorldPosition(new Vector3());
    const target = camera
      .getWorldPosition(new Vector3())
      .add(camera.getWorldDirection(new Vector3()).multiplyScalar(30))
      .clamp(MIN_BOUNDS, MAX_BOUNDS);

    return {
      position: new Cylindrical().setFromVector3(position),
      target: new Cylindrical().setFromVector3(target),
    };
  }

  private get cameraPreset(): CameraPreset | null {
    if (this.cameraPresetIndex === null) return null;
    return this.cameraPresets[this.cameraPresetIndex];
  }

  private get cameraPresetIndex() {
    return this._cameraPresetIndex;
  }

  private set cameraPresetIndex(value: number | null) {
    if (value === null) {
      clearTimeout(this.enableUserControlsTimeout);
      this.disableUserControls();
    }
    this._cameraPresetIndex = value;
    const cameraPresetEvent = new CustomEvent(UIEvents.UpdateCameraPreset, {
      detail: value,
    });
    window.dispatchEvent(cameraPresetEvent);
  }

  private get cameraRoomPreset(): CameraPreset | null {
    if (this.cameraRoomIndex === null) return null;
    return this.cameraRoomPresets[this.cameraRoomIndex];
  }

  private get cameraRoomIndex() {
    return this._cameraRoomIndex;
  }

  private set cameraRoomIndex(value: number | null) {
    this._cameraRoomIndex = value;
  }

  private set positionOffsetWeight(value: number) {
    if (value === this._positionOffsetWeight) return;
    this._positionOffsetWeight = value;

    gsap.killTweensOf(this, "_positionOffsetWeight");
    gsap.to(this, {
      _positionOffsetWeight: value,
      ease: "sine.inOut",
      duration: 5,
    });
  }

  private get positionOffsetWeight() {
    return this._positionOffsetWeight;
  }

  constructor() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.camera = new PerspectiveCamera(
      70,
      this.windowWidth / this.windowHeight,
      0.1,
      20000,
    );

    this.cameraSceneTarget = new Vector3(4, 8, 4);
    this.cameraSceneTargetCylndrical = new Cylindrical().setFromVector3(
      this.cameraSceneTarget,
    );
    this.cameraTarget = this.cameraSceneTarget.clone();
    this.cameraTargetDirection = new Vector3();
    this.cameraTargetCross = new Vector3();
    this.cameraCylndrical = new Cylindrical().setFromVector3(
      this.camera.position,
    );
    this.camera.rotation.reorder(ROTATION_ORDER); // so we can offset the device orientation controls based on camera euler
    this.cameraPositionRotationObject = this.camera.clone(); // cloning the camera as creating a new Object3D was buggy (lookAt messed up)
    this.cameraPositionRotationObject.rotation.reorder(ROTATION_ORDER); // so we can offset the device orientation controls based on camera euler

    this.onOrientation = _throttle(this.onOrientation.bind(this), 17);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onSwipe = this.onSwipe.bind(this);
    this.onUserStart = this.onUserStart.bind(this);
    this.enterSpace = this.enterSpace.bind(this);
    this.onStepUpdated = this.onStepUpdated.bind(this);
    this.goToScreen = this.goToScreen.bind(this);
    this.onScrollSwipeUp = this.onScrollSwipeUp.bind(this);
    this.onScrollSwipeDown = this.onScrollSwipeDown.bind(this);
    this.enableUserControls = this.enableUserControls.bind(this);
    this.disableUserControls = this.disableUserControls.bind(this);
    this.onCountdownComplete = this.onCountdownComplete.bind(this);

    // this.scrollStripe = new ScrollSwipe({
    //   target: document.body, // can be a div, or anything else you want to track scroll/touch events on
    //   scrollSensitivity: 5, // the lower the number, the more sensitive
    //   touchSensitivity: 0, // the lower the number, the more senitive,
    //   scrollPreventDefault: true, // prevent default option for scroll events
    //   touchPreventDefault: true, // prevent default option for touch events
    //   scrollCb: this.onScrollSwipe,
    //   touchCb: this.onScrollSwipe,
    // });

    window.addEventListener(UIEvents.Start, this.onUserStart);
    window.addEventListener(
      TimelineEvents.CountdownComplete,
      this.onCountdownComplete,
    );
  }

  public init(): void {
    this.camera.parent.add(this.cameraPositionRotationObject);
    window.addEventListener(
      "mousemove",
      _throttle(this.onMouseMove.bind(this), 17),
      { passive: true },
    );
    window.addEventListener("deviceorientation", this.onOrientation, {
      passive: true,
    });
    SwipeListener(document.body);

    window.addEventListener(ScreenEvents.GoTo, this.goToScreen);
    window.addEventListener(AppEvents.Step, this.onStepUpdated);
  }

  public setCameraPresets(
    cameraPresetGroup: Object3D,
    cameraRoomPresetGroup: Object3D,
  ): void {
    this.cameraPresets = [];
    cameraPresetGroup.traverse(child => {
      if (child.type === "PerspectiveCamera") {
        this.cameraPresets.push(
          CameraController.calculateCameraPreset(child as PerspectiveCamera),
        );
      }
    });
    cameraRoomPresetGroup.traverse(child => {
      if (child.type === "PerspectiveCamera") {
        this.cameraRoomPresets.push(
          CameraController.calculateCameraPreset(child as PerspectiveCamera),
        );
      }
    });

    const start = new Vector3(-5, 23, 5);
    const cpOne = new Vector3(-5, 0, 5);
    const cpTwo = new Vector3(2, 0, -2);
    const end = new Vector3().setFromCylindrical(
      this.cameraPresets[0].position,
    );

    this.introPath = new CubicBezierCurve3(start, cpOne, cpTwo, end);

    this.cameraCylndrical.setFromVector3(this.introPath.getPoint(0));
    this.cameraSceneTargetCylndrical.set(0, 0, 0);
  }

  private onCountdownComplete() {
    this.allowExploreOnEntered = false;
  }

  private onStepUpdated({ detail }: IStepEvent) {
    if (detail === Step.Show) {
      this.positionOffsetWeight = 0.33;
    } else {
      this.positionOffsetWeight = 1;
    }

    if (detail === Step.Enter) {
      if (window.location.search.indexOf("skip-intro") > -1) {
        this.jumpToCameraPreset(0);
        this.enableUserControls();
        const introCompleteEvent = new CustomEvent(
          TimelineEvents.IntroComplete,
        );
        window.dispatchEvent(introCompleteEvent);
      } else {
        this.enterSpace();
      }
    }
    if (detail === Step.PostShow) {
      this.playUtterances = false;
      if (window.location.search.indexOf("credits") > -1) {
        this.jumpToCameraPreset(0);
      } else {
        this.goToCreditsPreset();
      }
    }
    if (detail === Step.PostCredits) {
      this.playUtterances = true;
    }
  }

  private onUserStart(): void {
    this.requestOrientationPermission();
  }

  private async requestOrientationPermission() {
    if (
      typeof DeviceMotionEvent !== "undefined" &&
      typeof DeviceMotionEvent.requestPermission === "function"
    ) {
      // iOS 13+
      try {
        await DeviceOrientationEvent.requestPermission();
      } catch (error) {
        console.warn(error);
      }
    } else {
      console.warn("Orientation not supported");
    }
  }

  private enableUserControls(): void {
    this.userControlsEnabled = true;
    window.addEventListener("keydown", this.onKeyDown);
    document.body.addEventListener("swipe", this.onSwipe);
    window.addEventListener("wheelup", this.onScrollSwipeUp);
    window.addEventListener("wheeldown", this.onScrollSwipeDown);

    const userControlsEvent = new CustomEvent(UIEvents.UserControls, {
      detail: true,
    });
    window.dispatchEvent(userControlsEvent);
  }

  private disableUserControls(): void {
    this.userControlsEnabled = false;
    clearTimeout(this.enableUserControlsTimeout);
    window.removeEventListener("keydown", this.onKeyDown);
    document.body.removeEventListener("swipe", this.onSwipe);
    window.removeEventListener("wheelup", this.onScrollSwipeUp);
    window.removeEventListener("wheeldown", this.onScrollSwipeDown);
    const userControlsEvent = new CustomEvent(UIEvents.UserControls, {
      detail: false,
    });
    window.dispatchEvent(userControlsEvent);
  }

  private onOrientation(event: DeviceOrientationEvent) {
    const { alpha, beta, gamma } = event;
    const MAX_INPUT_BETA = 25;
    const MAX_INPUT_GAMMA = 35;

    let _beta = this.windowWidth < this.windowHeight ? beta : gamma;
    if (this.orientationCalibrationValues.length > 60 * 6) {
      this.orientationCalibrationValues.shift();
    }
    this.orientationCalibrationValues.push(_beta);
    let calibration = this.orientationCalibrationValues.reduce((acc, val) => {
      return acc + val;
    });
    calibration /= this.orientationCalibrationValues.length;
    _beta -= calibration;

    this.yOffsetTarget = convertToRange(
      _beta,
      [-MAX_INPUT_BETA, MAX_INPUT_BETA],
      [-0.25, 0.25],
    );
    this.xOffsetTarget = 0;

    if (alpha < 180) {
      this.xOffsetTarget = convertToRange(
        alpha,
        [0, MAX_INPUT_GAMMA],
        [0, -0.25],
      );
    } else {
      this.xOffsetTarget = convertToRange(
        alpha,
        [360 - MAX_INPUT_GAMMA, 360],
        [0.25, 0],
      );
    }
  }

  private onMouseMove(event: MouseEvent): void {
    this.xOffsetTarget = convertToRange(
      event.clientX,
      [0, this.windowWidth],
      [-0.25, 0.25],
    );
    this.yOffsetTarget = convertToRange(
      event.clientY,
      [0, this.windowHeight],
      [0.15, -0.15],
    );
  }

  private onKeyDown(event: KeyboardEvent): void {
    if (event.key.toLowerCase() === "arrowleft") {
      this.incCameraPreset(-1);
    }

    if (event.key.toLowerCase() === "arrowright") {
      this.incCameraPreset(1);
    }
  }

  private onSwipe(event: any) {
    if (event.detail.directions.left || event.detail.directions.up) {
      this.incCameraPreset(1);
    }

    if (event.detail.directions.right || event.detail.directions.down) {
      this.incCameraPreset(-1);
    }
  }

  private onScrollSwipeUp() {
    this.incCameraPreset(1);
  }

  private onScrollSwipeDown() {
    this.incCameraPreset(-1);
  }

  private calculateTravelTime(cameraPreset: CameraPreset): number {
    const travelDist = this.camera.position.distanceTo(
      new Vector3().setFromCylindrical(cameraPreset.position),
    );
    return Math.max(travelDist * 0.4, 3.5);
  }

  private goToScreen(event: IGoToScreenEvent): void {
    this.cameraPresetIndex = null;
    this.disableUserControls();
    gsap.killTweensOf(this.cameraCylndrical);
    gsap.killTweensOf(this.cameraSceneTargetCylndrical);
    clearTimeout(this.droneAudioTimeout);
    this.cameraRoomIndex = event.detail;
    const travelTime = this.calculateTravelTime(this.cameraRoomPreset);

    (this.camera.parent as MainScene).ambientAudio.dronePitch = "high";
    this.droneAudioTimeout = setTimeout(() => {
      (this.camera.parent as MainScene).ambientAudio.dronePitch = "low";
    }, (travelTime + CAMERA_PULL_OUT_TIME - 2) * 1000);

    const cameraPositionToCylindrical = CameraController.findShortestRouteRoundCylindrical(
      this.cameraCylndrical,
      this.cameraRoomPreset.position,
    );

    const cameraTargetToCylindrical = CameraController.findShortestRouteRoundCylindrical(
      this.cameraSceneTargetCylndrical,
      this.cameraRoomPreset.target,
    );

    gsap.to(this.cameraCylndrical, {
      theta: cameraPositionToCylindrical.theta,
      ease: "sine.inOut",
      delay: CAMERA_PULL_OUT_TIME,
      duration: travelTime,
      onComplete: () => {
        CameraController.clampCylindricalTheta(this.cameraCylndrical);
        const completeEvent = new CustomEvent(ScreenEvents.GoToComplete);
        window.dispatchEvent(completeEvent);
      },
    });

    gsap.to(this.cameraCylndrical, {
      keyframes: [
        {
          y: this.cameraCylndrical.y + 0.75,
          duration: CAMERA_PULL_OUT_TIME,
          ease: "sine.inOut",
        },
        {
          y: cameraPositionToCylindrical.y + 0.75,
          duration: travelTime,
          ease: "sine.inOut",
        },
        {
          y: cameraPositionToCylindrical.y,
          duration: CAMERA_PULL_IN_TIME,
          ease: "sine.inOut",
        },
      ],
    });

    gsap.to(this.cameraCylndrical, {
      keyframes: [
        {
          radius: cameraPositionToCylindrical.radius * 0.5,
          duration: travelTime * 0.5 + CAMERA_PULL_OUT_TIME,
          ease: "sine.inOut",
        },
        {
          radius: cameraPositionToCylindrical.radius,
          duration: travelTime * 0.5 + CAMERA_PULL_IN_TIME,
          ease: "sine.inOut",
        },
      ],
    });

    setTimeout(() => {
      const halfwayEvent = new CustomEvent(ScreenEvents.GoToHalfway);
      window.dispatchEvent(halfwayEvent);
    }, travelTime * 0.5 * 1000 + CAMERA_PULL_OUT_TIME * 1000);

    gsap.to(this.cameraSceneTargetCylndrical, {
      ...cameraTargetToCylindrical,
      ease: "power4.inOut",
      delay: CAMERA_PULL_OUT_TIME,
      duration: travelTime,
      onComplete: () =>
        CameraController.clampCylindricalTheta(
          this.cameraSceneTargetCylndrical,
        ),
    });
    uiSfx.play("camera-go-to-screen");
  }

  private incCameraPreset(direction: -1 | 1 = 1) {
    if (this.cameraPresetIndex === null) this.cameraPresetIndex = 0;
    let newIndex = this.cameraPresetIndex + direction;
    if (newIndex >= this.cameraPresets.length) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = this.cameraPresets.length - 1;
    }
    this.goToCameraPreset(newIndex);
  }

  private jumpToCameraPreset(index: number): void {
    this.cameraPresetIndex = index;

    requestAnimationFrame(() => {
      this.cameraCylndrical.copy(this.cameraPreset.position);
      this.cameraSceneTargetCylndrical.copy(this.cameraPreset.target);
    });
  }

  private goToCameraPreset(index: number): void {
    this.disableUserControls();
    gsap.killTweensOf(this.cameraCylndrical);
    gsap.killTweensOf(this.cameraSceneTargetCylndrical);
    clearTimeout(this.droneAudioTimeout);
    this.cameraPresetIndex = index;
    const travelTime = this.calculateTravelTime(this.cameraPreset);

    (this.camera.parent as MainScene).ambientAudio.dronePitch = "high";
    this.droneAudioTimeout = setTimeout(() => {
      (this.camera.parent as MainScene).ambientAudio.dronePitch = "low";
    }, (travelTime - 2) * 1000);

    const cameraPositionToCylindrical = CameraController.findShortestRouteRoundCylindrical(
      this.cameraCylndrical,
      this.cameraPreset.position,
    );

    const cameraTargetToCylindrical = CameraController.findShortestRouteRoundCylindrical(
      this.cameraSceneTargetCylndrical,
      this.cameraPreset.target,
    );

    const utterance =
      cameraPresetUtterances[index % cameraPresetUtterances.length];
    if (utterance && this.playUtterances) {
      setTimeout(() => {
        uiSfx.play(utterance.key, utterance);
      }, 999);
    }

    const onComplete = () => {
      if (utterance) {
        this.enableUserControlsTimeout = setTimeout(
          this.enableUserControls,
          Math.max(0, utterance.duration + 999 - travelTime * 1000),
        );
      } else {
        this.enableUserControls();
      }

      CameraController.clampCylindricalTheta(this.cameraCylndrical);
    };

    gsap.to(this.cameraCylndrical, {
      ...cameraPositionToCylindrical,
      ease: "power3.inOut",
      duration: travelTime,
      onComplete,
    });

    gsap.to(this.cameraSceneTargetCylndrical, {
      ...cameraTargetToCylindrical,
      ease: "power4.inOut",
      duration: travelTime,
      onComplete: () => {
        CameraController.clampCylindricalTheta(
          this.cameraSceneTargetCylndrical,
        );
      },
    });
  }

  private enterSpace(): void {
    const control = { t: 0 };
    const targetControl = { t: 0 };
    const length = this.introPath.getLength();
    const duration = length * 0.25;
    uiSfx.play("camera-switch-preset");

    (this.camera.parent as MainScene).ambientAudio.dronePitch = "high";
    setTimeout(() => {
      (this.camera.parent as MainScene).ambientAudio.dronePitch = "low";
    }, duration * 0.75 * 1000);

    const onUpdate = () => {
      const position = this.introPath.getPoint(control.t);
      this.cameraCylndrical.setFromVector3(position);
    };

    const onComplete = () => {
      const introCompleteEvent = new CustomEvent(TimelineEvents.IntroComplete);
      window.dispatchEvent(introCompleteEvent);
      (this.camera.parent as MainScene).ambientAudio.dronePitch = "low";

      if (this.allowExploreOnEntered) {
        this.cameraPresetIndex = 0;

        const utterance = cameraPresetUtterances[0];
        if (utterance && this.playUtterances) {
          uiSfx.play(utterance.key, utterance);
          this.enableUserControlsTimeout = setTimeout(
            this.enableUserControls,
            utterance.duration,
          );
        } else {
          this.enableUserControls();
        }
      }
    };

    gsap.to(control, {
      t: 1,
      duration,
      onUpdate,
      onComplete,
      ease: "power3.linear",
    });

    const startTarget = new Vector3(0, 0, 0);
    const currentTarget = startTarget.clone();
    const endTarget = new Vector3().setFromCylindrical(
      this.cameraPresets[0].target,
    );

    const onUpdateTarget = () => {
      this.cameraSceneTargetCylndrical.setFromVector3(
        currentTarget.lerpVectors(startTarget, endTarget, targetControl.t),
      );
    };

    gsap.to(targetControl, {
      t: 1,
      duration,
      onUpdate: onUpdateTarget,
      ease: "power4.inOut",
    });
  }

  private goToCreditsPreset() {
    this.disableUserControls();

    const start = new Vector3().setFromCylindrical(
      this.cameraRoomPreset.position,
    ); // -8, 14, -8
    const cpOne = new Vector3(-3, 21, -3);
    const cpTwo = new Vector3(-5, 2, 5);
    const end = new Vector3().setFromCylindrical(
      this.cameraPresets[0].position,
    ); // 7, 5, -7
    const path = new CubicBezierCurve3(start, cpOne, cpTwo, end);

    const control = { t: 0 };
    const targetControl = { t: 0 };
    const length = path.getLength();
    const duration = length * 0.5;



    (this.camera.parent as MainScene).ambientAudio.dronePitch = "high";
    setTimeout(() => {
      (this.camera.parent as MainScene).ambientAudio.dronePitch = "low";
    }, duration * 0.75 * 1000);

    const onUpdate = () => {
      const position = path.getPoint(control.t);
      this.cameraCylndrical.setFromVector3(position);
    };

    const onComplete = () => {
      this.enableUserControls();
      this.cameraRoomIndex = null;
      CameraController.clampCylindricalTheta(this.cameraCylndrical);
    };

    gsap.to(control, {
      t: 1,
      duration,
      onUpdate,
      onComplete,
      ease: "power3.inOut",
    });

    const startTarget = new Vector3().setFromCylindrical(
      this.cameraSceneTargetCylndrical,
    );
    const currentTarget = startTarget.clone();
    const endTarget = new Vector3().setFromCylindrical(
      this.cameraPresets[0].target,
    );

    const onUpdateTarget = () => {
      this.cameraSceneTargetCylndrical.setFromVector3(
        currentTarget.lerpVectors(startTarget, endTarget, targetControl.t),
      );
    };

    gsap.to(targetControl, {
      t: 1,
      duration,
      onUpdate: onUpdateTarget,
      ease: "power4.inOut",
    });
  }

  private updateCameraTarget(delta: number): void {
    this.yOffsetCurrent +=
      (this.yOffsetTarget - this.yOffsetCurrent) * 0.075 * delta;
    this.xOffsetCurrent +=
      (this.xOffsetTarget - this.xOffsetCurrent) * 0.075 * delta;

    this.cameraSceneTarget.setFromCylindrical(this.cameraSceneTargetCylndrical);
    this.cameraTargetDirection
      .subVectors(
        this.cameraSceneTarget,
        this.cameraPositionRotationObject.position,
      )
      .normalize();
    this.cameraTarget
      .copy(this.cameraPositionRotationObject.position)
      .add(this.cameraTargetDirection);

    this.cameraTarget.y += this.yOffsetCurrent;
    this.cameraTargetCross.crossVectors(
      this.cameraTargetDirection,
      this.cameraPositionRotationObject.up,
    );
    this.cameraTarget.add(
      this.cameraTargetCross.multiplyScalar(this.xOffsetCurrent),
    );
  }

  public onResize(): void {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;

    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
  }

  public update(delta: number): void {
    this.time += delta;
    // We're storing camera position and rotation seperate from camera so we can lerp between the control modes
    this.cameraPositionRotationObject.position.setFromCylindrical(
      this.cameraCylndrical,
    );

    this.cameraPositionRotationObject.position.x +=
      noiseX.noise2D(0, this.time * 0.002) * 0.15 * this.positionOffsetWeight;
    this.cameraPositionRotationObject.position.y +=
      noiseY.noise2D(this.time * 0.004, 0) * 0.2 * this.positionOffsetWeight;
    this.cameraPositionRotationObject.position.z +=
      noiseZ.noise2D(0, this.time * 0.002) * 0.1 * this.positionOffsetWeight;
    this.updateCameraTarget(delta);

    this.camera.position.copy(this.cameraPositionRotationObject.position);
    this.cameraPositionRotationObject.lookAt(this.cameraTarget); // look just in front of camera in direction of target

    // We need to make sure that the rotation y doesn't flip between minus and positive as it messes up the lerp between orientation controls and device controls.
    // So, let's always make sure the rotation y is a positive number
    if (this.cameraPositionRotationObject.rotation.y < 0) {
      this.cameraPositionRotationObject.rotation.y += TWO_PI;
    }

    const { x, y, z } = this.cameraPositionRotationObject.rotation;
    this.camera.rotation.set(x, y, z);
  }
}
