import { SUGGESTIONS, SUGGESTIONS_DURATION } from "../../../CONSTANTS";

export default class Suggestions {
  private el: HTMLDivElement;
  private currentIndex: any;
  private timer: any;

  constructor(el: HTMLDivElement) {
    this.el = el;
    this.currentIndex = 0;
    this.init = this.init.bind(this);
  }

  public init(): void {
    this.el.style.display = "block";
    this.el.innerHTML = SUGGESTIONS[0];
    this.timer = setInterval(() => {
      if (this.currentIndex === SUGGESTIONS.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex = this.currentIndex + 1;
      }
      this.el.innerHTML = SUGGESTIONS[this.currentIndex];
    }, SUGGESTIONS_DURATION);
  }

  public kill(): void {
    requestAnimationFrame(() => {
      clearInterval(this.timer);
      this.el.style.display = "none";
    });
  }
}
