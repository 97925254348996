import { LoadingManager, TextureLoader, AudioLoader } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import { AppEvents } from "./Events";

class ExtendedLoadingManager extends LoadingManager {
  private onLoadCallbacks: Function[] = [];

  constructor(props?) {
    super(props);

    this.onLoad = this.callOnLoadCallbacks.bind(this);
  }

  callOnLoadCallbacks() {
    window.dispatchEvent(new CustomEvent(AppEvents.Loaded));
    // Allow the events triggered by AppEvents.Loaded to takeplace before running heavy callbacks
    setTimeout(() => {
      this.onLoadCallbacks.forEach(callback => callback());
      requestAnimationFrame(() => {
        window.dispatchEvent(new CustomEvent(AppEvents.Ready));
      });
    }, 333);
  }

  addOnLoadCallback(callback: Function) {
    this.onLoadCallbacks.push(callback);
  }
}

const manager = new ExtendedLoadingManager();
export const textureLoader = new TextureLoader(manager);
export const gltfLoader = new GLTFLoader(manager);
export const audioLoader = new AudioLoader();

export default manager;
