import {
  Scene,
  PerspectiveCamera,
  Texture,
  MeshBasicMaterial,
  FrontSide,
  DoubleSide,
  Mesh,
  Vector3,
  SphereGeometry,
  Object3D,
  Fog,
} from "three";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";
import loadingManager, { textureLoader, gltfLoader } from "../util/Loaders";
import VideoScreenController from "./VideoScreenController";
import AmbientAudio from "./AmbientAudio";
import CameraController from "./CameraController";
import Pond from "./Pond";
import OuterScene from "./OuterScene";
import Bird from "./Bird";
import Drone from "./Drone";
import Landscape from "./Landscape";

import {
  LO_RESOLUTION_MAX,
  MED_RESOLUTION_MAX,
  BIRD_COUNT,
  BIRD_SETTINGS,
  BIRD_HEIGHT_VARIATION,
} from "../../CONSTANTS";
import { app, MartineRosePanopticonApp } from "../../client";

// import model from "../../models/panopticon-v4.gltf";
import model from "../../models/Model-drone.gltf";

import mapsHI from "../../maps/04122020/HIGH/70/*.{png,jpg}";
import mapsMED from "../../maps/04122020/MED/70/*.{png,jpg}";
import mapsLO from "../../maps/04122020/LOW/70/*.{png,jpg}";

import mapWaterNormals from "../../maps/waternormals.{png,jpg}";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

export default class MainScene extends Scene {
  public camera: PerspectiveCamera;
  private videoScreenController: VideoScreenController;
  public ambientAudio: AmbientAudio;
  private cameraController?: CameraController;
  private debugCameraControls?;
  private outerScene?: OuterScene;
  private pond: Pond;
  private drones: Drone[] = [];
  private loadedTextures: Map<string, Texture> = new Map();
  private loadedModel?: GLTF;
  private birdsInc = 0;
  private birds: any[] = [];
  private birdTarget: Mesh;

  private static get modelMaps() {
    const gl = app.webGLContext;
    const maxTextureSize = parseInt(gl.getParameter(gl.MAX_TEXTURE_SIZE));
    const resolution = window.innerWidth * window.innerHeight;

    console.log(`gl.MAX_TEXTURE_SIZE: ${gl.getParameter(gl.MAX_TEXTURE_SIZE)}`);
    console.log(
      `gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS: ${gl.getParameter(
        gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS,
      )}`,
    );

    if (resolution <= LO_RESOLUTION_MAX) {
      console.log("Use LO resolution");
      return mapsLO;
    }

    if (maxTextureSize < 4000) {
      console.log("Use LO resolution (max texture size)");
      return mapsLO;
    }

    if (resolution <= MED_RESOLUTION_MAX) {
      console.log("Use MED resolution");
      return mapsMED;
    }

    if (maxTextureSize < 8000) {
      console.log("Use MED resolution (max texture size)");
      return mapsMED;
    }

    if (MartineRosePanopticonApp.isSafari) {
      console.log("Use MED resolution (is Safari)");
      return mapsMED;
    }

    if (MartineRosePanopticonApp.deviceGrade !== "high") {
      console.log("Use MED resolution (is lower grade device)");
      return mapsMED;
    }

    console.log("Use HI resolution");
    return mapsHI;
  }

  constructor() {
    super();
    this.fog = new Fog(0xffffff, 10, 666);
    this.birdTarget = new Mesh(
      new SphereGeometry(1, 12, 12),
      new MeshBasicMaterial({ color: 0xff0000 }),
    );
    this.birdTarget.visible = false;
    this.add(this.birdTarget);

    this.ambientAudio = new AmbientAudio();
    this.add(this.ambientAudio);
    this.ambientAudio.init();

    this.videoScreenController = new VideoScreenController();
    this.add(this.videoScreenController);
    this.videoScreenController.init();

    if (window.location.search.indexOf("trackball") > -1) {
      this.camera = new PerspectiveCamera(
        60,
        window.innerWidth / window.innerHeight,
        0.1,
        20000,
      );
      this.camera.position.set(8, 30, 0);
      this.debugCameraControls = new TrackballControls(
        this.camera,
        document.body,
      );
      this.add(this.camera);
    } else {
      this.cameraController = new CameraController();
      this.camera = this.cameraController.camera;
      this.camera.add(this.ambientAudio.ambientListener);
      this.camera.add(this.ambientAudio.screenListener);
      this.add(this.camera);
      this.cameraController.init();
    }
  }

  public init(): void {
    this.loadModel();
  }

  private loadModel() {
    loadingManager.addOnLoadCallback(this.prepareModels.bind(this));

    textureLoader.load(mapWaterNormals.jpg, texture => {
      texture.flipY = false;
      this.loadedTextures.set(mapWaterNormals, texture);
    });

    const maps = MainScene.modelMaps;
    for (const key in maps) {
      textureLoader.load(maps[key].jpg || maps[key].png, texture => {
        texture.flipY = false;
        this.loadedTextures.set(key, texture);
      });
    }

    gltfLoader.load(model, gltf => (this.loadedModel = gltf));
  }

  private prepareModels() {
    this.add(this.loadedModel.scene);
    this.updateMatrixWorld();

    const multipleObjectTextures = ["Door", "Firedoor", "Doormat"];
    this.loadedTextures.forEach((map, key) => {
      const objects = [];
      if (multipleObjectTextures.includes(key)) {
        this.traverse(function(child) {
          if (child.name === key) {
            objects.push(child);
          }
        });
      } else {
        const found = this.getObjectByName(key);
        if (found) {
          objects.push(found);
        }
      }
      if (objects.length) {
        objects.forEach(object => {
          if (object.name.toLowerCase().indexOf("plant") > -1) {
            object.material = new MeshBasicMaterial({
              color: 0xffffff,
              map,
              side: DoubleSide,
              transparent: true,
            });
          } else {
            object.material = new MeshBasicMaterial({
              color: 0xffffff,
              map,
              side: FrontSide,
            });
          }
        });
      } else {
        console.warn(`No object found for ${key}`);
      }
    });

    this.getObjectByName("Rotorgroup").children.forEach(group => {
      (group.children[0] as Mesh).material = new MeshBasicMaterial({
        color: 0x000000,
      });
    });
    const drone = this.getObjectByName("Drone");
    drone.parent.remove(drone);
    this.drones.push(new Drone(drone));
    this.drones.push(new Drone(drone));
    this.drones.push(new Drone(drone));
    this.drones.forEach(drone => this.add(drone));
    const ground = this.getObjectByName("Ground");
    ground.visible = false;

    const waterObject = this.getObjectByName("waterplaceholder") as Mesh;
    this.pond = new Pond(waterObject, this.loadedTextures.get(mapWaterNormals));
    this.add(this.pond);
    waterObject.parent.remove(waterObject);

    for (let i = 0; i < BIRD_COUNT; i++) {
      const x =
        Math.random() * BIRD_SETTINGS.bounds.x - BIRD_SETTINGS.bounds.x / 2;
      const y =
        this.birdTarget.position.y +
        (Math.random() - 0.5) * BIRD_HEIGHT_VARIATION;
      const z =
        Math.random() * BIRD_SETTINGS.bounds.z - BIRD_SETTINGS.bounds.z / 2;
      const bird = Bird(new Vector3(x, y, z));
      this.birds.push(bird);
      this.add(bird.mesh);
    }

    const landscape = Landscape();
    landscape.mesh.position.y = -25;
    this.add(landscape.mesh);

    this.outerScene = new OuterScene();
    this.add(this.outerScene);
    this.environment = this.outerScene.environment;
    if (this.pond) this.pond.updateSunPosition(this.outerScene.sunPosition);

    if (this.cameraController) {
      this.cameraController.setCameraPresets(
        this.getObjectByName("Camerapresets"),
        this.getObjectByName("Camerarooms"),
      );
    }
    this.videoScreenController.setupScreens(this);
  }

  public update(delta: number): void {
    if (this.cameraController && !this.debugCameraControls) {
      this.cameraController.update(delta);
      if (this.drones.length) {
        const cylindrical = this.cameraController.cameraCylndrical.clone();
        this.drones.forEach(drone => {
          cylindrical.theta += Math.PI * 2 * (1 / (this.drones.length + 1));
          drone.update(delta, cylindrical);
        });
      }
    }
    if (this.debugCameraControls) this.debugCameraControls.update();
    if (this.pond) this.pond.update(delta);

    this.birdsInc += 0.01 * delta;
    this.birdTarget.position.y = Math.sin(this.birdsInc * 1.25) * 15 + 35;
    this.birdTarget.position.x = Math.cos(this.birdsInc * 1.8) * 5 + 5;
    this.birdTarget.position.z = Math.sin(this.birdsInc) * 40;
    this.birds.forEach(b => {
      b.applyBehaviors(this.birdTarget.position, this.birds);
      b.update(delta);
    });
  }

  public onResize(): void {
    if (this.cameraController) this.cameraController.onResize();
  }
}
